import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, map } from "rxjs";
import planungshinweisSelectors from "src/app/core/stores/planungshinweis/planungshinweis.selectors";
import { PlanungshinweisStatusMap } from "src/app/models/enums/planungshinweis-status";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { PlanungshinweisStatus } from "src/app/models/openapi/model/planungshinweis-status";

@Pipe({
  name: "hasPlanungshinweis",
})
export class HasPlanungshinweisPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(start: Date | string, end: Date | string, kanal: Kanal): Observable<string> {
    const von = new Date(start).toISOString();
    const bis = new Date(end).toISOString();
    return this.store
      .select(planungshinweisSelectors.selectPlanungshinweiseByTimeRange(von, bis, kanal))
      .pipe(
        map((planungshinweise) => {
          if (!planungshinweise) {
            return PlanungshinweisStatusMap[PlanungshinweisStatus.KEIN_STATUS].background;
          }

          if (
            planungshinweise.some(
              (planungshinweis) => planungshinweis.status === PlanungshinweisStatus.PROBLEMATISCH,
            )
          ) {
            return PlanungshinweisStatusMap[PlanungshinweisStatus.PROBLEMATISCH].background;
          }

          return PlanungshinweisStatusMap[PlanungshinweisStatus.KEIN_STATUS].background;
        }),
      );
  }
}
