import { Dictionary } from "@ngrx/entity";
import { AnyObject, KeySelector } from "./object-utils";

export type SortingDirection = "asc" | "desc";

export type SortingKeyFn<T> = (item: T) => string | number | Date;

export type SortingCriteria<T> = {
  keyFn: SortingKeyFn<T>;
  direction: SortingDirection;
};

export type Comparator<T> = (a: T, b: T) => number;

/**
 * Utility Type, der eine Union aller Eigenschaften eines Objekts `T` enthält, die vom Typ `Array` sind.
 *
 * @example
 * ```ts
 * type MyType = {
 *   a: string;
 *   b: number;
 *   c: string[];
 *   d: number[];
 * }
 *
 * type ArrayPropertiesOfMyType = ArrayProperties<MyType>; // "c" | "d"
 * ```
 */
export type ArrayProperties<T extends object> = {
  [K in keyof T]: T[K] extends Array<any> ? K : never;
}[keyof T];

/**
 * TODO testen, ob {@link predicate} wirklich einen Schlüssel erzeugt, der bei mehreren Objekten
 * in {@link array} "der selbe" ist, da in {@link Map.get} auf Referenzgleichheit geprüft wird
 */
export function groupByPredicateToMap<T, Q>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => Q,
) {
  return array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());
}

export function groupBy<T>(array: Iterable<T>, keySelector: KeySelector<T>): Record<string, T[]> {
  return Array.from(array).reduce(
    (acc: Record<string, T[]>, item: T) => {
      const key = keySelector(item);
      if (key in acc) {
        // found key, push new item into existing array
        acc[key].push(item);
      } else {
        // did not find key, create new array
        acc[key] = [item];
      }
      return acc;
    },
    {}, // start with empty object
  );
}

/**
 * Groups objects by key and returns a map of key to grouped objects including the key and the key's value.
 *
 * @returns
 * ```
 * {
 *   [keyValue]: {
 *     key: key,
 *     value: keyValue,
 *     items: T[]
 *   }
 * }
 * ```
 */
export function groupByKey<T extends { [key: string]: any }, K extends keyof T>(
  objects: T[],
  key: K,
): Partial<Record<T[K], { key: K; value: T[K]; items: T[] }>> {
  const groupsByKey: Partial<Record<T[K], { key: K; value: T[K]; items: T[] }>> = {};
  for (const obj of objects) {
    const keyValue = obj[key];
    let group = groupsByKey[keyValue];
    if (!group) {
      group = { key, value: keyValue, items: [] };
      groupsByKey[keyValue] = group;
    }
    group.items.push(obj);
  }
  return groupsByKey;
}

/**
 * Function that compares two strings or numbers or dates.
 */
export function compare<T extends string | number | Date>(a: T, b: T): number {
  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  } else if (typeof a === "number" && typeof b === "number") {
    return a < b ? -1 : a > b ? 1 : 0;
  } else if (a instanceof Date && b instanceof Date) {
    return a.getTime() - b.getTime();
  } else {
    throw new Error("Type not supported: " + typeof a);
  }
}

/**
 * Gibt einen {@link Comparator<T>} zurück, der zwei Objekte `T` vergleicht, indem er die `keyFn`s der `criteria` anwendet.
 */
export function orderBySortingCriteriaFactory<T>(criteria: SortingCriteria<T>[]): Comparator<T> {
  return (a, b) => {
    for (const criterion of criteria) {
      const keyA = criterion.keyFn(a);
      const keyB = criterion.keyFn(b);
      const comparison = compare(keyA, keyB);
      if (comparison !== 0) {
        return criterion.direction === "asc" ? comparison : -comparison;
      }
    }
    return 0;
  };
}

/**
 * ACHTUNG: Diese Methode gilt nur für Primitive nicht für komplexe Objekte.
 * Überprüft ob zwei Arrays die gleichen primitiven Elemente enthalten, unabhängig von ihrer Reihenfolge.
 * @param a erstes Array
 * @param b zweites Array
 * @returns `true` wenn beide Arrays die gleichen Elemente enthalten, sonst `false`
 */
export function sameMembers<T>(a: T[], b: T[]): boolean {
  return a.every((v) => b.includes(v)) && b.every((v) => a.includes(v));
}

/**
 * Finds an object in an array based on a given predicate function and returns a tuple
 * containing the found object and its index.
 *
 * @param array The array to search.
 * @param predicate A predicate function to determine if an object matches the search criteria.
 * @returns A tuple with the found object and its index. If not found, both values are undefined.
 */
export const findObjectWithIndex = <T>(
  array: T[],
  predicate: (element: T) => boolean,
): [T | undefined, number | undefined] => {
  const index = array.findIndex(predicate);
  return index !== -1 ? [array[index], index] : [undefined, undefined];
};

type Item<T> = {
  type: "item";
  /**
   * `value` ist das Objekt vom Typ `T`.
   */
  value: T;
  /**
   * `memberOfGroup` ist der `groupKey` der Gruppe, zu der das Objekt gehört.
   */
  memberOfGroup: string | null;
  depth: number;
};
type Group<T extends AnyObject, K extends keyof T> = {
  type: "group";
  /**
   * `key` ist der `key` nach dem gruppiert wurde.
   */
  key: K;
  /**
   * `keyValue` ist der Wert des `key`s.
   */
  keyValue: T[K];
  /**
   * `groupKey` ist ein eindeutiger Schlüssel für die Gruppe, der sich aus den `keyValue`s
   * der Gruppenhierarchie zusammensetzt.
   */
  groupKey: string;
  /**
   * `memberOfGroup` ist der `groupKey` der Gruppe, zu der das Objekt gehört.
   */
  memberOfGroup: string | null;
  depth: number;
};

export type GroupedItem<T extends AnyObject, K extends keyof T> = Item<T> | Group<T, K>;

/**
 * `GROUP_KEY_DELIMITER` ist das Trennzeichen, das in {@link Group.groupKey} verwendet wird,
 * um die `keyValue`s der Gruppenhierarchie zu trennen.
 */
export const GROUP_KEY_DELIMITER = "____";

/**
 * Funktion, die ein Array von Objekten `T` und eine Liste von `key`s von `T` annimmt,
 * nach denen die Liste gruppiert werden soll.
 * Anstatt ein verschachteltes Array zurückzugeben, gibt sie ein Array von Objekten des Typs
 * {@link Group<T, K>} zurück.
 *
 * Wenn `propertiesToGroupBy` leer ist, besteht das zurückgegebene Array aus {@link Item<T>}s.
 *
 * Die Reihenfolge des zurückgegebenen Arrays ist die gleiche wie die Reihenfolge des ursprünglichen Arrays.
 *
 * @param array Das Array von Objekten `T`, das gruppiert werden soll.
 * @param keysToGroupBy Die Liste von `key`s von `T`, nach denen gruppiert werden soll.
 *
 * @returns Ein Array von Objekten des Typs {@link Group<T, K>} oder {@link Item<T>}, wenn `propertiesToGroupBy` leer ist.
 */
export function insertGroupObjects<T extends AnyObject, K extends keyof T & string>(
  array: T[],
  keysToGroupBy: K[],
): GroupedItem<T, K>[] {
  if (array.length === 0) return [];
  if (!keysToGroupBy || keysToGroupBy.length === 0)
    return array.map((value) => ({ type: "item", value, memberOfGroup: null, depth: 0 }));
  const createComparatorObject = (obj: T) => {
    return keysToGroupBy.reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {} as Partial<T>);
  };
  const isEqualByComparator = (a: T, comparator: Partial<T>) =>
    keysToGroupBy.every((key) => a[key] === comparator[key]);

  let comparatorObject: Partial<T> = {};
  let groupKey: string | null = null;
  let depth = 0;
  // In folgendem Prozess wird eine eindimensionale Gruppierungshierachie erstellt
  // Die Anzahl der keysToGroupBy bestimmt die Tiefe der Hierarchie
  // Nacheinander wird das Array durchlaufen und für jedes Objekt geprüft, ob es in die aktuelle Gruppe gehört
  // Wenn nicht, wird eine neue Gruppe erstellt und das Objekt wird der Gruppe hinzugefügt
  return array.reduce(
    (acc, item) => {
      const isSameGroup = isEqualByComparator(item, comparatorObject);
      if (!isSameGroup) {
        let previousGroupKey: string | null = null;
        keysToGroupBy.forEach((key, index) => {
          const keyValue = item[key];
          groupKey = [previousGroupKey, keyValue].filter((v) => !!v).join(GROUP_KEY_DELIMITER);
          depth = groupKey.split(GROUP_KEY_DELIMITER).length - 1;
          // Hier ist bekannt, dass das Objekt nicht in der aktuellen Gruppe ist, allerdings weiß man noch nicht auf welcher Ebene
          // Achtung: Wenn sich die oberste Gruppe geändert hat, müssen alle Gruppen neu erstellt werden, da die Gruppenhierarchie neu beginnt
          if (keyValue !== comparatorObject[key]) {
            acc.push({
              type: "group",
              key,
              keyValue,
              groupKey,
              memberOfGroup: previousGroupKey,
              depth,
            });
            // Sobald sich eine Gruppe geändert hat, muss das comparatorObject für alle folgenden Gruppen geleert werden
            keysToGroupBy.slice(index + 1).forEach((key) => delete comparatorObject[key]);
          }
          previousGroupKey = groupKey;
        });
        comparatorObject = createComparatorObject(item);
      }
      acc.push({ type: "item", value: item, memberOfGroup: groupKey, depth: depth + 1 });
      return acc;
    },
    [] as GroupedItem<T, K>[],
  );
}

/**
 * Wandelt eine Array von Objekten `T` in ein Dictionary um, wobei die Werte des `keyProperty` als Schlüssel
 * verwendet werden.
 * @param list Array von Objekten `T`
 * @param keyProperty Name der Eigenschaft, die als Schlüssel verwendet werden soll
 * @returns
 */
export function createDictionary<T>(list: T[], keyProperty: keyof T): Dictionary<T> {
  return list.reduce((acc, obj) => {
    const keyValue = obj[keyProperty] as string;
    acc[keyValue] = obj;
    return acc;
  }, {} as Dictionary<T>);
}

/**
 * Überprüft, ob ein gegebenes Tupel nur aus nicht-nullen und nicht-undefinierten Werten besteht.
 * Kann zb in einer `filter`-Methode verwendet werden, um nur Tupel zu behalten, in denen alle Werte definiert sind.
 *
 * @usage
 * ```ts
 * const tuples: [string, number | null][] = [
 *   ["a", 1],
 *   ["b", null],
 *   ["c", 3],
 * ];
 *
 * const nonNullTuples = tuples.filter(allValuesDefined); // [["a", 1], ["c", 3]]
 *
 * // kann auch für Objekte verwendet werden und prüft dann, ob alle Werte definiert sind
 * const objectsWithNullableValues: { a: string | null; b: number | null; }[] = [
 *   {
 *     a: "a",
 *     b: null,
 *   },
 *   {
 *     a: "b",
 *     b: 2
 *   }
 * ];
 *
 * const onlyDefinedValues = objectsWithNullableValues.filter(allValuesDefined); // [{ a: "b", b: 2 }]
 * ```
 */
export function allValuesDefined<T extends unknown>(
  tuple: T,
): tuple is { [K in keyof T]: NonNullable<T[K]> } {
  return tuple && Object.values(tuple).every((item) => item !== null && item !== undefined);
}

/**
 * Returns the given array if it has values or null if it is empty
 */
export function arrayWithValuesOrNull<T extends Array<any>>(array: T) {
  return array.length ? array : null;
}

/**
 * Takes a value that might be an array and returns it as an array.
 */
export function asArray<T>(value: T | T[]): T[] {
  if (!value) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

/**
 * Takes a value that might be an array and returns it as a single value if length is 1, null if length is 0 or throws an error if length is > 1.
 */
export function asSingleValueOrNull<T>(value: T | T[]): T | null {
  if (Array.isArray(value)) {
    if (value.length === 1) {
      return value[0];
    }
    if (value.length === 0) {
      return null;
    }
    throw new Error(`Expected array to have length 0 or 1 but got ${value.length}`);
  }
  return value ? value : null;
}

/**
 * Nimmt einen Wert und ein Array, in dem dieser Wert enthalten sein kann, und gibt
 * ein neues Array zurück, aus dem der Wert entfernt wurde, wenn er enthalten war, oder
 * in das er hinzugefügt wurde, wenn er nicht enthalten war.
 */
export function toggleValueInArray<T>(value: T, array: T[]): T[] {
  return array.includes(value) ? array.filter((v) => v !== value) : [...array, value];
}

/**
 * Nimmt ein Array von Werten und ein Array, in dem diese Werte enthalten sein können, und gibt
 * ein neues Array zurück, in dem die Werte entweder hinzugefügt oder entfernt wurden.
 * Wird nicht sortiert.
 */
export function toggleValuesInArray<T>(values: T[], array: T[]): T[] {
  return values.reduce((acc, value) => toggleValueInArray(value, acc), array);
}

export function compareArraysIgnoringOrder<T>(
  a: T[],
  b: T[],
  compareFn?: (a: T, b: T) => boolean,
): boolean {
  if (a.length !== b.length) {
    return false;
  }
  const compare = compareFn ?? ((a: T, b: T) => a === b);
  return a.every((item) => b.some((other) => compare(item, other)));
}

export function arrayUnique<T extends string | number>(...array: T[][]): T[] {
  const _arrays = array.flat();
  return Array.from(new Set([..._arrays]));
}

/**
 * Moves an element within an array from one index to another and shifts the other elements accordingly.
 * @param keys The array of keys
 * @param oldIndex The index of the element to move
 * @param newIndex The index to move the element to
 * @returns The updated array
 */
export function shiftElement<T>(keys: T[], oldIndex: number, newIndex: number): T[] {
  if (
    oldIndex < 0 ||
    oldIndex >= keys.length ||
    newIndex < 0 ||
    newIndex >= keys.length ||
    oldIndex === newIndex
  ) {
    return keys; // If either oldIndex or newIndex is out of range or they are equal, return the original array
  }

  const updatedKeys = [...keys];
  const [removed] = updatedKeys.splice(oldIndex, 1);
  updatedKeys.splice(newIndex, 0, removed);
  return updatedKeys;
}

/**
 * Sort an array of objects by their order according to a second array.
 * @param columnsToOrder
 * @param currentColumnOrder
 * @returns
 */
export function sortColumnsByIndexOrder<T>(columnsToOrder: T[], currentColumnOrder: T[]): T[] {
  const sortedList = [...columnsToOrder];

  sortedList.sort((a, b) => {
    const columnPosition1 = currentColumnOrder.indexOf(a);
    const columnPosition2 = currentColumnOrder.indexOf(b);

    // Handling the case where an element is not found in currentColumnOrder
    if (columnPosition1 === -1 && columnPosition2 === -1) {
      return 0; // If both elements are not found, keep them in their original order
    } else if (columnPosition1 === -1) {
      return 1; // If only the first element is not found, move it to the end
    } else if (columnPosition2 === -1) {
      return -1; // If only the second element is not found, move it to the end
    }

    return columnPosition1 - columnPosition2;
  });

  return sortedList;
}
