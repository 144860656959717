import { Component, ContentChild, Input, ViewChild } from "@angular/core";
import { AbstractControl, FormControlDirective, FormControlName, Validators } from "@angular/forms";
import { LabelDirective } from "@progress/kendo-angular-label";
import { FormControlState, FormControlValueTypes, NgrxFormControlDirective } from "ngrx-forms";
import { Icons } from "src/app/models/icons";

/**
 * Komponente, die ein Label für ein Formularfeld rendert und zusätzlich
 * einen "*" anzeigt, wenn das Feld ein Pflichtfeld ist.
 */
@Component({
  selector: "app-input-label",
  templateUrl: "./input-label.component.html",
  styleUrls: ["./input-label.component.scss"],
})
export class InputLabelComponent {
  /**
   * Der Labeltext.
   */
  @Input() text = "";

  /**
   * Wenn gesetzt, wird ein Hinweis-Symbol neben dem Label angezeigt mit dem hinterlegten Text.
   */
  @Input() hint = "";

  /**
   * Wenn true, wird ein Ladeindikator angezeigt.
   * Kann z.B. verwendet werden, wenn das Label bei einem Dropdown verwendet wird,
   * dessen Optionen asynchron geladen werden.
   */
  @Input() loading = false;

  /**
   * Workaround, weil verschachtelte ng-content nicht gefunden werden.
   * Wird benötigt, um in {@link }
   * @see https://github.com/angular/angular/issues/16299
   */
  @Input() control: FormControlState<FormControlValueTypes> | null;

  @ContentChild(NgrxFormControlDirective)
  ngrxFormControlDirectiveFromContent?: NgrxFormControlDirective<unknown>;
  @ViewChild(NgrxFormControlDirective)
  ngrxFormControlDirectiveFromView?: NgrxFormControlDirective<unknown>;

  @Input() angularControl?: AbstractControl;
  @ContentChild(FormControlDirective) angularFormControlDirective?: FormControlDirective;
  @ContentChild(FormControlName) angularFormControlName?: FormControlName;

  Icons = Icons;

  /**
   * Das Feld, für das das Label ist. Sollte im Idealfall eine Referenz auf ein Element
   * sein, das im Template via #variable definiert wurde.
   */
  @Input() for?: LabelDirective["for"];

  get isRequired() {
    return this.controlState?.isRequired;
  }

  /**
   * Ob das Feld invalide ist.
   */
  get isInvalid() {
    return this.controlState?.isInvalid;
  }

  get isPristine() {
    return this.controlState?.isPristine;
  }

  get isTouched() {
    return this.controlState?.isTouched;
  }

  get controlState(): FormControlStateValue | undefined {
    return this.ngRxFormControlState ?? this.angularFormControlState;
  }

  get shouldShowError(): boolean {
    // wir nutzen touched und pristine, weil Kendo leider nicht sauber die Blur Events handelt 😭
    return !!(this.isInvalid && (this.isTouched || !this.isPristine));
  }

  get ngRxFormControlState() {
    const state =
      this.control ??
      this.ngrxFormControlDirectiveFromContent?.state ??
      this.ngrxFormControlDirectiveFromView?.state;

    if (!state) {
      return undefined;
    }

    return {
      isPristine: state?.isPristine ?? false,
      isInvalid: state?.isInvalid ?? false,
      isRequired: Boolean(state?.userDefinedProperties?.isRequired) ?? false,
      isTouched: state?.isTouched ?? false,
    };
  }

  get angularFormControlState() {
    const control =
      this.angularControl ??
      this.angularFormControlName?.control ??
      this.angularFormControlDirective?.control;

    if (!control) {
      return undefined;
    }

    return {
      isPristine: control.pristine,
      isInvalid: control.invalid,
      isRequired: control.hasValidator(Validators.required) ?? false,
      isTouched: control.touched,
    };
  }
}

type FormControlStateValue = {
  isPristine: boolean;
  isInvalid: boolean;
  isRequired: boolean;
  isTouched: boolean;
};
