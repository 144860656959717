import {
  addDays,
  addMinutes,
  addMonths,
  addSeconds,
  areIntervalsOverlapping,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
  Interval,
  isBefore,
  isFriday,
  isMatch,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  isWithinInterval,
  minutesToSeconds,
  parse,
  secondsToMinutes,
  setHours,
  setMinutes,
  setSeconds,
  subMinutes,
} from "date-fns";
import de from "date-fns/locale/de";

/**
 * Sammlung an Methoden, die DateFns-Funktionen implementieren und im gesamten Projekt als static Methoden verfügbar
 * machen. Sollen, wenn möglich verwendet oder erweitert werden um eine einheitlichen Umgang mit Dates zu erreichen.
 */
export class DateFnsService {
  static isBefore(leftDate: string | null | undefined, rightDate: string | null) {
    return leftDate && rightDate
      ? isBefore(
          DateFnsService.parseStringToDate(leftDate),
          DateFnsService.parseStringToDate(rightDate),
        )
      : false;
  }
  /**
   * Konvertiert ein Datum in einen Wochentag
   * @param date kann entweder ein Date oder ein Datum als `string` im Format `yyyy-MM-dd` sein
   * @returns Wochentag als `string` im Format `EEEE` z.B. `Montag`
   */
  static getWeekday(date: Date | string): string {
    const dateToFormat = typeof date === "string" ? DateFnsService.parseStringToDate(date) : date;
    return format(dateToFormat, "EEEE", { locale: de });
  }

  /**
   * Konvertiert ein Datum in eine Kalenderwoche z.B. `"04"`
   */
  static getCalendarWeek(date: Date | string): string {
    const dateToFormat = typeof date === "string" ? DateFnsService.parseStringToDate(date) : date;
    return format(dateToFormat, "II", { locale: de });
  }

  /**
   * Konvertiert ein Datum in einen Monat
   * @param date kann entweder ein Date oder ein Datum als `string` im Format `yyyy-MM-dd` sein
   * @returns Monat als `string` im Format `MMMM` z.B. `Januar`
   */
  static getMonth(date: Date | string): string {
    const dateToFormat = typeof date === "string" ? DateFnsService.parseStringToDate(date) : date;
    return format(dateToFormat, "MMMM", { locale: de });
  }

  static formatDateAsTimeString(datum: number | Date, formatString?: string): string {
    return format(datum, formatString ?? "HH:mm", { locale: de });
  }

  static formatDateAsTimeStringOrNull(date: number | Date | null | undefined): string | null {
    return date ? format(date, "HH:mm", { locale: de }) : null;
  }

  static formatDateAsStringOrNull(datum: number | Date | null | undefined): string | null {
    return datum ? format(datum, "yyyy-MM-dd", { locale: de }) : null;
  }

  static formatDateAsString(datum: number | Date): string {
    return format(datum, "yyyy-MM-dd", { locale: de });
  }

  static formatDateAsGermanDateString(datum: number | Date): string {
    return format(datum, "dd.MM.yyyy", { locale: de });
  }

  static parseStringToDate(datum: string): Date;
  static parseStringToDate(datum: string | null): Date | null;
  static parseStringToDate(datum: string | null): Date | null {
    return datum ? parse(datum, "yyyy-MM-dd", new Date(), { locale: de }) : null;
  }

  static parseStringToGermanDateString(datum: string): string {
    return format(parse(datum, "yyyy-MM-dd", new Date(), { locale: de }), "dd.MM.yyyy");
  }

  static secondsToMinutes(seconds: number): number {
    return secondsToMinutes(seconds);
  }

  /**
   * Konvertiert die Dauer in Sekunden zu einem `string` im Format `'mm:ss'`
   * @param durationInSeconds Dauer in Sekunden, die über 60 Minuten hinausgehen können
   * @returns Dauer in `'mm:ss'`
   */
  static parseSecondsToTimeString(durationInSeconds: number): string {
    return (
      Math.floor(durationInSeconds / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (durationInSeconds % 60).toString().padStart(2, "0")
    );
  }

  /**
   * Erstellt aus einer Uhrzeit und der Referenz eines optionalen Datums, ein Date Objekt.
   * @param time Uhrzeit im String Format HH:ss
   * @param date Datum entweder als String im Fromat yyyy-MM-dd oder bereits als Date Objekt
   * @returns das Date Objekt mit entsprechender Uhrzeit
   */
  static parseDateAndTimeToDateObject(time: string, date?: Date | string): Date {
    let parseDatum: Date | string;
    if (date instanceof Date) {
      parseDatum = date;
    } else if (typeof date === "string") {
      parseDatum = new Date(date);
    } else {
      parseDatum = new Date();
    }

    if (isMatch(time, "HH:mm")) {
      return parse(time, "HH:mm", parseDatum, { locale: de });
    }
    return parse(time, "HH:mm:ss", parseDatum, { locale: de });
  }

  /**
   * Konvertiert die Dauer von einem `string` im Format `'mm:ss'` zu Sekunden als `number`
   * @param duration Dauer in `'mm:ss'`, wobei `'mm'` nicht auf 60 begrenzt ist
   * @returns Dauer in Sekunden
   */
  static parseDurationStringToSeconds(duration: string): number {
    return duration
      ? minutesToSeconds(Number(duration.split(":")[0])) + Number(duration.split(":")[1])
      : 0;
  }

  /**
   * Passt ein Interval so an, dass Jahr, Monat und Tag an ein Interval angepasst werden,
   * Wird verwendet, um Intervale nur anhand der Zeit auf Überlappungen zu untersuchen (z.B. überlappende Intervale auf selbem Sendeplatz).
   * @param timeString Uhrzeit im Format `HH:mm`
   * @param interval Interval (start und end als Date, keine number!), das vorgibt, welches Jahr, Monat und Tag gesetzt werden.
   */
  static patchDateWithTimeToIntervalDay(timeString: string, interval: Interval): Date {
    if (!(interval.start instanceof Date) || !(interval.end instanceof Date))
      throw new Error("Invalider Intervaltyp: number");

    const parsedWithStartDate = DateFnsService.parseDateAndTimeToDateObject(
      timeString,
      interval.start,
    );
    const parsedWithEndDate = DateFnsService.parseDateAndTimeToDateObject(timeString, interval.end);
    return isBefore(parsedWithEndDate, interval.end) ? parsedWithEndDate : parsedWithStartDate;
  }

  /**
   * Gibt zurück ob das gegebene Datum zwischen `start` und `end` liegt
   */
  static dateBetweenDates(date: Date, start: Date, end: Date): boolean {
    return isWithinInterval(date, { start, end });
  }

  /**
   * Berechnet die Tage zwischen zwei Datumsangaben
   * @param dateLeft Datum im Format `yyyy-MM-dd`
   * @param dateRight Datum im Format `yyyy-MM-dd`
   * @returns Anzahl Tage (+/-)
   */
  static daysBetweenDates(dateLeft: string, dateRight: string): number {
    return differenceInDays(
      DateFnsService.parseStringToDate(dateLeft),
      DateFnsService.parseStringToDate(dateRight),
    );
  }

  // formatTimeWithoutSecondsAsString(uhrzeit: string): string {
  //   if (isMatch(uhrzeit, "HH:mm:ss")) {
  //     // const splittedUhrzeit = uhrzeit.split(":");
  //     // return `${splittedUhrzeit[0]}:${splittedUhrzeit[1]}`;
  //     return format(parse(uhrzeit, "HH:mm:ss", new Date(), { locale: de }), "HH:mm");
  //   }
  //   return uhrzeit;
  // }

  // formatTimeWithSeconds(uhrzeit: string): string {
  //   if (isMatch(uhrzeit, "HH:mm")) {
  //     return format(parse(uhrzeit, "HH:mm", new Date(), { locale: de }), "HH:mm:ss");
  //   }
  //   return uhrzeit;
  // }

  static tageChecker: { [key: string]: (wochentag: Date) => boolean } = {
    Montag: (wochentag) => {
      return isMonday(wochentag);
    },
    Dienstag: (wochentag) => {
      return isTuesday(wochentag);
    },
    Mittwoch: (wochentag) => {
      return isWednesday(wochentag);
    },
    Donnerstag: (wochentag) => {
      return isThursday(wochentag);
    },
    Freitag: (wochentag) => {
      return isFriday(wochentag);
    },
    Samstag: (wochentag) => {
      return isSaturday(wochentag);
    },
    Sonntag: (wochentag) => {
      return isSunday(wochentag);
    },
  };

  static areIntervalsOverlapping(range1: Interval, range2: Interval, inclusive = false) {
    return areIntervalsOverlapping(range1, range2, {
      inclusive,
    });
  }

  static addMonths(date: Date | number, amount: number) {
    return addMonths(date, amount);
  }

  /**
   * Addiert auf das Datum eine Anzahl an Tagen
   * @param date Datum im Format `yyyy-MM-dd` oder als `Date`
   * @param days Tage zum aufaddieren
   * @returns Datum, welches x `days` später liegt
   */
  static addDays(date: string | Date, days: number) {
    const dateToAddTo = typeof date === "string" ? DateFnsService.parseStringToDate(date) : date;
    return addDays(dateToAddTo, days);
  }
  /**
   * Addieren von Minuten auf eine Uhrzeit
   *
   * @param date kann entweder ein Date oder eine Uhrzeit als `Date` sein
   * @param minutes Anzahl an Minuten, die addiert werden sollen
   * @returns Uhrzeit als `Date`
   */
  static addMinutes(date: Date | number, amount: number) {
    return addMinutes(date, amount);
  }

  static addSeconds(date: Date | number, amount: number) {
    return addSeconds(date, amount);
  }

  static subMinutes(date: Date | number, amount: number) {
    return subMinutes(date, amount);
  }

  /**
   * DateFns liefert je nach Reihenfolge der Parameter eine
   * Differenz mit Vorzeichen, wir sind aber unabhängig von der
   * Reihenfolge nur an der absoluten Differenz interessiert.
   */
  static getAbsoluteSecondsDifference(start: Date | number, end: Date | number) {
    return Math.abs(differenceInSeconds(start, end));
  }

  /**
   * DateFns liefert je nach Reihenfolge der Parameter eine
   * Differenz mit Vorzeichen, wir sind aber unabhängig von der
   * Reihenfolge nur an der absoluten Differenz interessiert.
   */
  static getAbsoluteMinuteDifference(start: Date | number, end: Date | number) {
    return Math.abs(differenceInMinutes(start, end));
  }

  /**
   * Erstellt aus den gegebenen Datums- und Zeit-Komponenten ein Date-Objekt
   * in der Zeitzone Z/Zulu/UCT+0.
   *
   * Eigentlich kennt publish.it keine Zeitzonen, das Javascript
   * Date-Objekt arbeitet jedoch zwingend immer mit Zeitzonen.
   * https://bobbyhadz.com/blog/javascript-create-date-without-timezone
   *
   * Wenn Date-Objekte gegeneinander verrechnet werden, müssen sich diese
   * daher in der gleichen, wohldefinierten Zeitzone (z.B. Z/Zulu/UTC+0) befinden.
   * Die Date-Objekte sind dadurch quasi nicht mehr "korrekt", aber liefern
   * ein richtiges Ergebnis, wenn man sie gegeneinander verrechnet.
   *
   * Wird ein Objekt ohne Angabe einer konkreten Zeitzone erstellt,
   * wird die lokale Zeitzone (Offset) verwendet, welche sich in
   * Abhängigkeit des Datums (Zeitumstellung) ändern kann.
   *
   * Konkretes Beispiel
   * Zeitumstellung Sommer-/Winterzeit am 30.10.22 von 03:00 Uhr auf 02:00 Uhr
   *
   * new Date("2022-10-30T03:00") - new Date("2022-10-30T02:00")
   * 7200000 / 1000 / 60 / 60 = 2
   * new Date("2022-10-30T03:00Z") - new Date("2022-10-30T02:00Z")
   * 3600000 / 1000 / 60 / 60 = 1
   *
   * Wichtig für das Debugging ist, dass Javascript die
   * Konsolenausgaben immer lokalisiert. Daher ist die Betrachtung der UTC Ticks
   * meistens hilfreicher und weniger verwirrend.
   *
   * @param date Datum im Format 'yyyy-MM-dd'
   * @param time Zeit im Format 'HH:mm'
   * @returns Zulu-Date
   */
  static createZuluDate(date: string, time: string): Date {
    return new Date(`${date}T${time}Z`);
  }

  /**
   * Erstellt ein Interval für eine Beginnzeit und Länge
   * @param beginnzeit als Startzeitpunkt des Intervals
   * @param laenge des Intervals in Sekunden
   * @param datePreset legt fest wie das Datum interpretiert werden soll
   * @returns Interval mit start und end
   */
  static createInterval(beginnzeit: string, laenge: number, datePreset: Date): Interval {
    const start = DateFnsService.parseDateAndTimeToDateObject(beginnzeit, datePreset);
    const end = DateFnsService.addSeconds(start, laenge);
    return { start, end };
  }

  /**
   * Erstellt aus dem gegebenen Date-Objekt eines neues Date-Objekt
   * in der Zeitzone Z/Zulu/UCT+0.
   *
   * Siehe Beschreibung zu createZuluDate()
   *
   * @date Ein Date-Objekt in lokaler Zeitzone
   * @returns Zulu-Date
   */
  static convertToZuluDate(date: Date): Date {
    const timezoneOffset = date.getTimezoneOffset();
    const year = `${date.getFullYear()}`;
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const dayOfMonth = `${date.getDate()}`.padStart(2, "0");
    const hours = `${date.getHours()}`.padStart(2, "0");
    const minutes = `${date.getMinutes()}`.padStart(2, "0");
    const dateString = `${year}-${month}-${dayOfMonth}T${hours}:${minutes}Z`;
    const zuluDate = new Date(dateString);
    zuluDate.setMinutes(zuluDate.getMinutes() - timezoneOffset);
    return zuluDate;
  }

  /**
   * Gibt ein neues Date Objekt mit veränderter Zeit zurück.
   * @param time Format: HH:mm:ss
   */
  static setTime(date: Date, time: string) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return setHours(setMinutes(setSeconds(date, seconds), minutes), hours);
    // const newDate = new Date(date);
    // newDate.setHours(hours, minutes, seconds, 0);
    // return newDate;
  }

  /**
   * Konvertiert ein Datum in die von der publish.it API erwartete ISO-String-Formatierung
   * ohne Zeitzoneninformationen (z.B. 2022-01-04T21:45:00)
   */
  static toPublitIsoString(date: Date): string {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss");
  }

  static toPublitIsoStringOrNull(date: Date | null): string | null {
    return date ? format(date, "yyyy-MM-dd'T'HH:mm:ss") : null;
  }
}
