import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import * as CustomValidators from "src/app/shared/form-inputs/custom-validators-ngrx";
import {
  createReducerAndCustomFormActions,
  createValidationRules,
  ValidationMap,
} from "src/app/utils/ngrx-forms";
import {
  initialState,
  OnDemandPageState,
  OnDemandSearchFormData,
  onDemandSearchFormId,
} from "./on-demand-page.model";

const validationRules = (form: OnDemandSearchFormData): ValidationMap<OnDemandSearchFormData> => {
  return {
    groupByPropertyTwo: [CustomValidators.distinctFrom(form.groupByPropertyOne)],
    useOperatorUND: [required],
    timerangeOnlineAb: [required],
    timerangeOnlineBis: [required],
  };
};

const { actions, reducer } = createReducerAndCustomFormActions<OnDemandPageState, "searchForm">(
  initialState,
  onDemandSearchFormId,
  "searchForm",
);

export const onDemandSearchFormActions = actions;

export const onDemandSearchFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.searchForm,
  createValidationRules(validationRules),
);
