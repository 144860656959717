import {
  Action,
  ActionReducer,
  createAction,
  createReducer,
  createSelector,
  MemoizedSelector,
  on,
  props,
} from "@ngrx/store";
import { format } from "date-fns";
import {
  AbstractControlState,
  Boxed,
  disable,
  enable,
  FormGroupState,
  FormState,
  KeyValue,
  markAsDirty,
  markAsPristine,
  markAsTouched,
  NgrxValueConverter,
  onNgrxForms,
  reset,
  setUserDefinedProperty,
  setValue,
  updateGroup,
  validate,
  ValidationErrors,
  ValidationFn,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";
import { required } from "ngrx-forms/validation";

type PropertyOf<T extends KeyValue> = T[keyof T];
type PropertyValidatorFn<Form extends KeyValue, Property = PropertyOf<Form>> = (
  value: Property | Boxed<Property> | null | undefined,
  form: Form | undefined,
) => ValidationErrors;

type FormValidatorFn<Form extends KeyValue> = (value: Form) => ValidationErrors;

export type ValidationMap<Form extends KeyValue> = {
  [K in keyof Form]?: ValidationFn<Form[K]>[];
} & {
  // Wir brauchen die Möglichkeit das Formular als ganzes zu validieren...
  __form?: FormValidatorFn<Form>[];
};

export type BoxedProperties<T extends object> = {
  [K in keyof T]: T[K] extends Boxed<any> ? K : never;
}[keyof T];

export const createValidationRules = <
  Form extends KeyValue,
  FeatureState extends KeyValue | undefined,
>(
  validationMapFactory: (form: Form, feature: FeatureState) => ValidationMap<Form>,
) => {
  return (form: FormGroupState<Form>, feature: FeatureState): FormGroupState<Form> => {
    // Wir rufen die Factory jedes mal auf, damit wir dynamische Validierungen basierend auf dem aktuellen Formularzustand haben.
    // Damit ermöglichen wir z.B. die Validierung von Feldern, die von anderen Feldern abhängen.
    const validationMap = validationMapFactory(form.value, feature);

    const propertyUpdatesWithValidationAndRequired = Object.keys(validationMap)
      // Wir filtern das __form raus, da wir das Formular als ganzes separat validieren.
      .filter((key: unknown): key is keyof Form => key !== "__form")
      .map((key) => {
        const validations = validationMap[key] ?? [];

        if (!validations) {
          return null;
        }

        const isRequired = validations.includes(required);

        return [
          key,
          (value: PropertyOf<Form>) =>
            setUserDefinedProperty(validate(value, validations), "isRequired", isRequired),
        ];
      })
      .filter((v) => v !== null);

    const propertyValidationRules = Object.fromEntries(propertyUpdatesWithValidationAndRequired);
    const stateWithValidatedProperties = updateGroup<Form>(propertyValidationRules)(form);

    const formValidationRules = validationMap.__form ?? [];
    const stateWithValidatedForm = validate(stateWithValidatedProperties, formValidationRules);

    return stateWithValidatedForm as FormGroupState<Form>;
  };
};

/**
 * Erstellt aus dem Initial-State des Formulars und einer FactoryFunktion, die die Validierungsregeln des Formulars beschreibt den Reducer.
 *
 * @param initialFormState Der Initiale Form-State (meistens ein leeres Formular)
 * @param validationMapFactory Eine Funktion, die aus dem aktuellen Formular den Validierungs-Map erstellt.
 * @returns Den fertigen Reducer für NgRX
 */
export const createFormGroupReducerWithValidation = <Form extends KeyValue>(
  initialFormState: FormGroupState<Form>,
  validationMapFactory: (form: Form) => ValidationMap<Form>,
) => {
  const rawReducer = createReducer(initialFormState, onNgrxForms());

  return wrapReducerWithFormStateUpdate(
    rawReducer,
    (s) => s,
    createValidationRules(validationMapFactory),
  );
};

//
// Erstellt ein Set von Custom-Actions für ein Formular.
// Die Actions sind spezifisch für das Formular und haben ein eindeutiges Präfix.
//
const customActions = <FormValue extends FormGroupState<KeyValue>>(actionPrefix: string) => ({
  ///////////////////////
  // Complete Form
  ///////////////////////
  setValue: createAction(`${actionPrefix} setValue`, props<{ value: FormValue["value"] }>()),
  patchValue: createAction(
    `${actionPrefix} patchValue`,
    props<{ value: Partial<FormValue["value"]> }>(),
  ),

  reset: createAction(`${actionPrefix} reset`),
  disable: createAction(`${actionPrefix} disable`),
  enable: createAction(`${actionPrefix} enable`),

  markAllAsTouched: createAction(`${actionPrefix} markAllAsTouched`),
  markAllAsPristine: createAction(`${actionPrefix} markAllAsPristine`),
  markAllAsDirty: createAction(`${actionPrefix} markAllAsDirty`),

  trySaving: createAction(`${actionPrefix} trySaving`),
  save: createAction(`${actionPrefix} save`),

  ///////////////////////
  // Multiple Controls
  ///////////////////////
  disableControls: createAction(
    `${actionPrefix} disableControls`,
    props<{ controls: (keyof FormValue["value"])[] }>(),
  ),
  enableControls: createAction(
    `${actionPrefix} enableControls`,
    props<{ controls: (keyof FormValue["value"])[] }>(),
  ),
  resetControls: createAction(
    `${actionPrefix} resetControls`,
    props<{ controls: (keyof FormValue["value"])[] }>(),
  ),
});

// Overload
export function createReducerAndCustomFormActions<
  Feature extends FormGroupState<KeyValue>,
  FormState extends FormGroupState<KeyValue> = Feature,
>(
  initalState: Feature,
  formId: string,
): {
  actions: ReturnType<typeof customActions<FormState>>;
  reducer: ActionReducer<Feature, Action>;
  selectors: typeof formSelectorsFactory<Feature, FormState>;
};
// Overload
export function createReducerAndCustomFormActions<
  Feature extends KeyValue,
  K extends keyof Feature,
  FormState extends FormGroupState<KeyValue> = Feature[K],
>(
  initalState: KeyValue,
  formId: string,
  formProperty: K,
): {
  actions: ReturnType<typeof customActions<FormState>>;
  reducer: ActionReducer<Feature, Action>;
  selectors: typeof formSelectorsFactory<Feature, FormState>;
};
//
// Erstellt einen Reducer und Custom-Actions für ein Formular in einem Feature.
//
export function createReducerAndCustomFormActions<
  Feature extends FormGroupState<KeyValue> | KeyValue,
  K extends keyof Feature,
  FormState extends FormGroupState<KeyValue> = Feature extends FormGroupState<KeyValue>
    ? Feature
    : Feature[K],
>(
  initalState: Feature,
  formId: string,
  formProperty?: K,
): {
  actions: ReturnType<typeof customActions<FormState>>;
  reducer: ActionReducer<Feature, Action>;
  selectors: typeof formSelectorsFactory<Feature, FormState>;
} {
  const actionPrefix = `[CustomFormAction:${formId}]`;
  const actions = customActions<FormState>(actionPrefix);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const formLocator = formProperty as any;

  const customActionReducer = createReducer<Feature>(
    initalState, // Wir nutzen hier einen Platzhalter. Wenn dieser Reducer aufgerufen wird, ist hier das wirkliche Feature vorhanden.
    on(actions.reset, (state) => {
      return updateFormInState(state, formLocator, reset);
    }),
    on(actions.patchValue, (state, { value }) => {
      return updateFormInState(state, formLocator, patchValue(value as unknown));
    }),
    on(actions.setValue, (state, { value }) => {
      return updateFormInState(state, formLocator, setValue(value as unknown));
    }),
    on(actions.disableControls, (state, { controls }) => {
      return updateFormControlsInState(state, formLocator, controls as string[], disable);
    }),
    on(actions.enableControls, (state, { controls }) => {
      return updateFormControlsInState(state, formLocator, controls as string[], enable);
    }),
    on(actions.resetControls, (state, { controls }) => {
      return updateFormControlsInState(state, formLocator, controls as string[], reset);
    }),
    on(actions.markAllAsTouched, actions.trySaving, (state) => {
      return updateFormInState(state, formLocator, markAsTouched);
    }),
    on(actions.markAllAsPristine, (state) => {
      return updateFormInState(state, formLocator, markAsPristine);
    }),
    on(actions.markAllAsDirty, (state) => {
      return updateFormInState(state, formLocator, markAsDirty);
    }),
    on(actions.disable, (state) => {
      return updateFormInState(state, formLocator, disable);
    }),
    on(actions.enable, (state) => {
      return updateFormInState(state, formLocator, enable);
    }),
  );

  return {
    reducer: customActionReducer,
    actions,
    selectors: formSelectorsFactory<Feature, FormState>,
  };
}

/**
 * Eine Factory-Function, die zu einem gegebenen Feature-Selector die passenden Selektoren für ein Formular zurückgibt.
 * @param selectFormState
 * @returns
 */
const formSelectorsFactory = <
  FeatureState extends KeyValue,
  FormState extends FormGroupState<FormValue>,
  FormValue extends KeyValue = FormState["value"],
>(
  selectFormState: MemoizedSelector<FeatureState, FormState>,
) => {
  return {
    selectFormState: selectFormState,
    selectFormData: createSelector(selectFormState, (state: FormState): FormValue => state.value),
    selectFormIsDirty: createSelector(
      selectFormState,
      (state: FormState): boolean => state.isDirty,
    ),
    selectFormIsValid: createSelector(
      selectFormState,
      (state: FormState): boolean => state.isValid,
    ),
  };
};

//
// Liefert das Formular aus einem Feature.
// Wenn das Feature selbst das Forumlar ist, wird das Feature zurückgegeben.
//
const getForm = <
  State extends KeyValue,
  Property extends keyof State | undefined,
  FormValue extends Property extends keyof State ? State[Property] : State,
>(
  state: State,
  property: Property,
) => (property ? state[property] : state) as FormGroupState<FormValue>;

//
// Aktualisiert in einem gegeben Feature das Formular mit dem formPropertyOnState-Parameter.
// Das Update wird durch die updateFn (z.B.: enable, disable, reset, etc...) durchgeführt.
//
const updateFormInState = <
  Feature extends KeyValue,
  Property extends keyof Feature | undefined,
  FormValue extends KeyValue,
>(
  state: Feature,
  formPropertyOnState: Property,
  updateFn: (state: FormGroupState<FormValue>) => FormGroupState<FormValue>,
): Feature => {
  if (!state) {
    return state;
  }
  const form = getForm(state, formPropertyOnState);
  const updatedForm = updateFn(form as unknown as FormGroupState<FormValue>);

  return (
    formPropertyOnState
      ? {
          ...state,
          [formPropertyOnState]: updatedForm,
        }
      : updatedForm
  ) as Feature;
};

//
// Aktualisiert in einem gegeben Feature die Formular-Controls, die in der controlsToUpdate-Liste enthalten sind.
// Das Update wird durch die updateFn (z.B.: enable, disable, reset, etc...) durchgeführt.
// Das Formular wird über den formPropertyOnState-Parameter aus dem Feature extrahiert.
//
const updateFormControlsInState = <
  Feature extends KeyValue,
  Property extends keyof Feature | undefined,
>(
  state: Feature,
  formPropertyOnState: Property,
  controlsToUpdate: string[],
  updateFn: (state: FormGroupState<KeyValue>) => FormGroupState<KeyValue>,
): Feature => {
  if (!state) {
    return state;
  }

  const form = getForm(state, formPropertyOnState);
  const typedControlsToUpdate = controlsToUpdate as (keyof typeof form)[];
  const updatedForm = updateFormControls(form, typedControlsToUpdate, updateFn);

  return (
    formPropertyOnState
      ? {
          ...state,
          [formPropertyOnState]: updatedForm,
        }
      : updatedForm
  ) as Feature;
};

//
// Aktualisiert in einem gegeben Formular die Controls, die in der controlsToUpdate-Liste enthalten sind.
// Das Update wird durch die updateFn (z.B.: enable, disable, reset, etc...) durchgeführt.
//
const updateFormControls = <FormValue extends KeyValue, TValue extends KeyValue>(
  form: FormGroupState<FormValue>,
  controlsToUpdate: string[],
  updateFn: (state: FormGroupState<TValue>) => FormGroupState<TValue>,
) => {
  const updatedControls = Object.fromEntries(
    Object.entries(form.controls).map(
      ([controlName, control]: [string, FormGroupState<TValue>]) => {
        if (controlsToUpdate.includes(controlName)) {
          return [controlName, updateFn(control)];
        }
        return [controlName, control];
      },
    ),
  );

  return {
    ...form,
    controls: updatedControls,
  };
};

const patchValue =
  <TValue>(value: TValue): ((state: AbstractControlState<TValue>) => FormState<TValue>) =>
  (state: AbstractControlState<TValue>) =>
    setValue(state, {
      ...state.value,
      ...value,
    });

export const dateValueConverter: NgrxValueConverter<Date, string> = {
  convertViewToStateValue: (value) => (value ? format(value, "yyyy-MM-dd") : ""),
  convertStateToViewValue: (value) => new Date(value),
};
