import { areIntervalsOverlapping } from "date-fns/fp";
import { Boxed, isBoxed, KeyValue, unbox, ValidationErrors, ValidationFn } from "ngrx-forms";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Wochentag } from "src/app/models/openapi/model/wochentag";
import { DateFnsService } from "src/app/services/date-fns.service";
import {
  KanalOffsetUtils,
  kanalTagesgrenzen,
  wochentagToDayOfWeek,
} from "src/app/utils/kanal-offset-utils";

export const valueBefore =
  <Form extends KeyValue>(formValue: Form, key: keyof Form, label: string) =>
  <T extends string | number | null>(value: T): ValidationErrors => {
    const otherValue = formValue[key] as T;
    if (!value || !otherValue) return {};
    return value > otherValue ? { valueBefore: { otherLabel: label } } : {};
  };

export const valueAfter =
  <Form extends KeyValue>(form: Form, key: keyof Form, label: string) =>
  <T extends string | number | null>(value: T): ValidationErrors => {
    const otherValue = form[key] as T;
    if (!value || !otherValue) return {};
    return value < otherValue ? { valueAfter: { otherLabel: label } } : {};
  };

export const valueBeforeWithReferenceKey =
  <Form extends KeyValue>(form: Form, key: keyof Form, label: string, referenceKey: keyof Form) =>
  <T extends string | number | null>(value: T): ValidationErrors => {
    const otherValue = form[key];
    const referenceValue = form[referenceKey];

    if (!otherValue || !referenceValue || !value) return {};
    return referenceValue > otherValue ? { valueBefore: { otherLabel: label } } : {};
  };

export const minLengthTrimmed =
  (minLength: number) =>
  <T extends string | undefined | null>(
    value: T | Boxed<T> | null | undefined,
  ): ValidationErrors => {
    const input = isBoxed(value) ? unbox(value) : value;

    if (typeof input !== "string" || !input) return {};
    const trimmed = input.trim();
    return trimmed?.length < minLength
      ? { minLengthTrimmed: { actual: trimmed.length, minLengthTrimmed: minLength } }
      : {};
  };

export const patternWithCustomError = (
  pattern: RegExp,
  errorMessage: string,
): ValidationFn<string | null> => {
  return (value: string | null): ValidationErrors => {
    if (!value || typeof value !== "string") return {};
    const valid = pattern.test(value);
    return valid
      ? {}
      : {
          pattern: {
            pattern: pattern.source,
            actual: value,
          },
        };
  };
};

export const numberBetween = (min: number, max: number): ValidationFn<number | null> => {
  return (value: number | null): ValidationErrors => {
    if (value === null || value === undefined) return {};
    return value < min || value > max ? { numberBetween: { min, max, actual: value } } : {};
  };
};

export const wochentag = (wochentag: Wochentag): ValidationFn<string | null> => {
  return (value: string | null): ValidationErrors => {
    if (!value) return {};
    const date = new Date(value);
    const day = date.getDay();
    return wochentagToDayOfWeek[wochentag] === day ? {} : { wochentag };
  };
};

/**
 * Validiert, ob die Beginnzeit vor der Endzeit liegt und falls nicht, ob die Endzeit vor der Tagesgrenze des Kanals liegt.
 * @param beginnzeitKey Key des Beginnzeit-Formularfelds
 * @param endzeitKey Key des Endzeit-Formularfelds
 * @param kanal der Kanal, für den die Tagesgrenze gelten soll
 */
export const endzeitVorBeginnzeitMitSendetagsgrenze = <
  Form extends KeyValue,
  BeginnzeitKey extends keyof Form,
  EndzeitKey extends keyof Form,
>(
  beginnzeitKey: BeginnzeitKey,
  endzeitKey: EndzeitKey,
  kanal: Kanal,
): ValidationFn<Form> => {
  const kanalTagesgrenze = kanalTagesgrenzen[kanal];

  if (!kanalTagesgrenze) {
    throw new DeveloperError("endzeitVorBeginnzeitMitSendetagsgrenze: Kanal has no Tagesgrenze", {
      kanal,
    });
  }

  return (value: Form): ValidationErrors => {
    if (!value) return {};

    const beginnzeit = value[beginnzeitKey];
    const endzeit = value[endzeitKey];

    if (!beginnzeit || !endzeit) return {};

    // korrekt, wenn Beginnzeit < Endzeit
    if (beginnzeit < endzeit) return {};

    // auch korrekt, wenn Beginnzeit >= Endzeit und Endzeit < Tagesgrenze
    const endzeitIstVorTagesgrenze = KanalOffsetUtils.isBetweenMidnightToTagesgrenze(
      endzeit,
      kanal,
    );
    if (endzeitIstVorTagesgrenze) return {};

    return { endzeitVorBeginnzeitMitSendetagsgrenze: kanalTagesgrenze };
  };
};

/**
 * Validiert, dass sich das Planungsobjekt mindestens 1 Minute mit dem Zeitinterval überschneidet.
 * Anfang und Ende werden dabei über die Formularfelder beginnzeit und laenge definiert.
 * @param timeRange Das Interval, in dem sich das Planungsobjekt befinden muss
 * @param beginnzeitKey Der Key des Beginnzeit-Formularfelds
 * @param laengeKey Der Key des Laenge-Formularfelds
 */
export const planungsobjektBlockansichtInTimeRange = <
  Form extends KeyValue,
  BeginnzeitKey extends keyof Form,
  LaengeKey extends keyof Form,
>(
  timeRange: Interval,
  beginnzeitKey: BeginnzeitKey,
  laengeKey: LaengeKey,
): ValidationFn<Form> => {
  if (!timeRange) {
    throw new DeveloperError("planungsobjektBlockansichtInTimeRange: timeRange is not defined");
  }

  const timeRangeOverlaps = areIntervalsOverlapping(timeRange);

  return (form: Form) => {
    if (!form) return {};

    const beginnzeit = form[beginnzeitKey];
    const laenge = form[laengeKey];

    if (!beginnzeit || !laenge) return {};

    const beginnzeitDate = DateFnsService.patchDateWithTimeToIntervalDay(beginnzeit, timeRange);
    const endzeitDate = DateFnsService.addSeconds(beginnzeitDate, laenge);

    const isWithinTimeRange = timeRangeOverlaps({ start: beginnzeitDate, end: endzeitDate });

    return isWithinTimeRange ? {} : { planungsobjektBlockansichtInTimeRange: true };
  };
};

export const atLeastOnePerGroup = <K extends keyof Form, Form extends KeyValue>(
  keyGroups: K[][],
) => {
  return (form: Form): ValidationErrors => {
    return keyGroups

      .map((keys) => keys
        .map((key) => form[key])
        // 0 soll ein valider Wert sein, daher prüfen wir nur auf undefined, null und leere Liste
        .some((value) => value !== undefined && value !== null && (value as any).length !== 0)
      )
      .every((groupHasValue) => groupHasValue)
      ? {}
      : { requiredControls: true };
  };
};

export const distinctRecordValues = <Form extends KeyValue>(form: Form): ValidationErrors => {
  const values = Object.values(form);
  return new Set(values).size === values.length ? {} : { distinctRecordValues: true };
};

export const distinctFrom = <Form extends KeyValue>(otherValue: Form[keyof Form]) => {
  return (value: Form[keyof Form]): ValidationErrors => {
    return value !== otherValue ? {} : { distinctFrom: { otherValue: otherValue as string } };
  };
};

export const notEqualTo = <Form extends KeyValue, T>(notEqualToValue: T) => {
  return (value: Form[keyof Form]): ValidationErrors => {
    return value !== notEqualToValue
      ? {}
      : { notEqualTo: { actual: value as string, comparand: notEqualToValue as string } };
  };
};
