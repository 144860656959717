<ng-container
  *ngIf="{
    formState: facade.formState$ | async,
    windowInput: facade.windowInput$ | async,
  } as vm"
>
  <form [ngrxFormState]="vm.formState">
    <div class="flex-container">Titel: {{ vm.windowInput?.planungsobjekt?.titel }}</div>

    <div class="flex-container">
      <app-form-field label="Folgennr. ab">
        <kendo-numerictextbox
          format="n0"
          [decimals]="0"
          [min]="1"
          [ngrxFormControlState]="vm.formState?.controls?.folgennummerAb"
          [step]="1"
        >
        </kendo-numerictextbox>
      </app-form-field>
      <app-form-field label="Folgennr. bis">
        <kendo-numerictextbox
          data-testid="folgennummerBis"
          format="n0"
          [decimals]="0"
          [min]="1"
          [ngrxFormControlState]="vm.formState?.controls?.folgennummerBis"
          [step]="1"
        >
        </kendo-numerictextbox>
      </app-form-field>
    </div>

    <div class="flex-container">
      <app-form-field label="Staffelnr.">
        <kendo-numerictextbox
          format="n0"
          [decimals]="0"
          [min]="1"
          [ngrxFormControlState]="vm.formState?.controls?.staffelnummer"
          [step]="1"
        >
        </kendo-numerictextbox>
      </app-form-field>

      <app-form-field label="Folgen in Staffel/Serie">
        <kendo-numerictextbox
          data-testid="anzahlFolgen"
          format="n0"
          [decimals]="0"
          [min]="1"
          [ngrxFormControlState]="vm.formState?.controls?.anzahlFolgen"
          [step]="1"
        >
        </kendo-numerictextbox>
      </app-form-field>
    </div>

    <label class="publit-checkbox flex-start verlinkungen">
      <input
        kendoCheckBox
        type="checkbox"
        [ngrxFormControlState]="vm.formState?.controls?.reihenfolgeVerlinken"
      />
      <span>Verlinkung des Typs 'Reihenfolge' zwischen den Programmen setzen</span>
    </label>

    <label class="publit-checkbox flex-start blackout-dates">
      <input
        kendoCheckBox
        type="checkbox"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="enableAuszulassendeDaten"
        (ngModelChange)="onEnableAuszulassendeDatenChange($event)"
      />
      <span>Daten ausschließen</span>
    </label>

    <div class="flex-start" style="flex-direction: row; gap: 1rem">
      <kendo-calendar
        *ngIf="enableAuszulassendeDaten"
        selection="multiple"
        [focusedDate]="facade.focusedDate$ | async"
        [ngrxFormControlState]="vm.formState?.controls?.auszulassendeDaten"
        [ngrxValueConverter]="valueConverter"
      >
      </kendo-calendar>

      <div
        *ngIf="vm.formState?.value?.auszulassendeDaten?.value?.length ?? 0 > 0"
        class="flex-container"
        style="flex-direction: column"
      >
        <h3 style="margin-bottom: 1rem">Ausgeschlossene Daten:</h3>
        <span *ngFor="let date of vm.formState?.value?.auszulassendeDaten?.value">
          {{ date | date: "dd.MM.yyyy" }}
        </span>
      </div>
    </div>
  </form>
  <div class="window-action-buttons">
    <button fillMode="outline" kendoButton type="button" (click)="onClose()">Abbrechen</button>
    <button
      class="primary-button"
      kendoButton
      themeColor="primary"
      type="button"
      [disabled]="vm.formState?.isPristine"
      (click)="onSave()"
    >
      Erstellen
    </button>
  </div>
</ng-container>
