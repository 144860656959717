import { createSelector } from "@ngrx/store";
import { unbox } from "ngrx-forms";
import permissionsSelectors from "src/app/core/stores/permissions/permissions.selectors";
import routerSelectors from "src/app/core/stores/router/router.selectors";
import { DateFnsService } from "src/app/services/date-fns.service";
import { YearDropdownItem } from "./on-demand-page.model";
import { onDemandPageFeature } from "./on-demand-page.reducer";
import { urlParamsToSearchFormValue } from "./on-demand-table/on-demand-table.utils";

const selectSearchFormState = onDemandPageFeature.selectSearchForm;
const selectSearchFormValue = createSelector(selectSearchFormState, (state) => state.value);

// Poor Man's Memoization
// We preserve the last valid values of the search form to avoid unnecessary layout crashes...
let lastValidZeitraum: { von: string; bis: string } | undefined = undefined;
const selectZeitraum = createSelector(selectSearchFormValue, (searchFormValue) => {
  const zeitraum = {
    von: searchFormValue.timerangeOnlineAb,
    bis: searchFormValue.timerangeOnlineBis,
  };

  if (!zeitraum.von || !zeitraum.bis) {
    return lastValidZeitraum;
  }

  lastValidZeitraum = zeitraum;

  return zeitraum;
});

const selectGroupByProperties = createSelector(selectSearchFormValue, (searchFormValue) =>
  [searchFormValue.groupByPropertyOne, searchFormValue.groupByPropertyTwo].filter((v) => !!v),
);
const selectOrderByProperty = createSelector(
  selectSearchFormValue,
  (searchFormValue) => searchFormValue.orderByProperty,
);
const selectLogicOperator = createSelector(selectSearchFormValue, (searchFormValue) =>
  searchFormValue.useOperatorUND ? "UND" : "ODER",
);
const selectFilterCriteria = createSelector(selectSearchFormValue, (searchFormValue) => ({
  redaktionen: unbox(searchFormValue.filterRedaktionen),
  genres: unbox(searchFormValue.filterGenres),
  planungskontexte: unbox(searchFormValue.filterPlanungskontexte),
  contentCommunities: unbox(searchFormValue.filterContentCommunities),
}));

const selectLogicOperatorQueryParam = createSelector(
  routerSelectors.selectQueryParam("logicOperator"),
  (logicOperator) => {
    //Lese keine Params aus, wenn keine vorhanden sind. Returned null, kein leeres Objekt!
    if (!logicOperator) return null;

    return logicOperator;
  },
);

const selectSearchFormFromQueryParams = createSelector(
  routerSelectors.selectQueryParams,
  urlParamsToSearchFormValue,
);

const selectQuickFilter = createSelector(
  onDemandPageFeature.selectQuickFilter,
  (quickFilter) => quickFilter,
);

const selectYearOrCustomRange = createSelector(
  permissionsSelectors.selectGeltungsbereichYearsForOnDemand,
  selectZeitraum,
  (years, { von, bis }) => {
    if (!von || !bis) return null;

    const year = years.find((year) => {
      const { von: yearVon, bis: yearBis } = year.value || {};
      if (yearVon !== undefined && yearBis !== undefined) {
        return yearVon === von && yearBis === bis;
      }
      return false;
    });

    if (year) return year;

    let customYearRange: YearDropdownItem = {} as YearDropdownItem;
    customYearRange = {
      text: `${DateFnsService.formatDateAsGermanDateString(
        new Date(von),
      )} - ${DateFnsService.formatDateAsGermanDateString(new Date(bis))}`,
      value: null,
      tooltip: "benutzerdefinierter Zeitraum",
    };

    return customYearRange;
  },
);

export default {
  selectLogicOperatorQueryParam,
  selectQuickFilter,
  selectYearOrCustomRange,
  selectSearchFormFromQueryParams,
  selectSearchFormState,
  selectSearchFormValue,
  selectZeitraum,
  selectGroupByProperties,
  selectOrderByProperty,
  selectLogicOperator,
  selectFilterCriteria,
};
