import { Injectable } from "@angular/core";
import { isSameDay } from "date-fns";
import { Planungskontext } from "../models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "../models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "../models/openapi/model/planungsobjekt-on-demand-dto";
import { DateFnsService } from "../services/date-fns.service";

export enum PlanungsobjektLocation {
  ListenKalenderAnsicht = "LISTEN?KALENDER_ANSICHT",
  Blockansicht = "BLOCKANSICHT",
  Merkliste = "MERKLISTE",
  Vorschlag = "VORSCHLAG",
  OnDemand = "ON_DEMAND",
}

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektUtils {
  static isLinear(planungsobjekt: PlanungsobjektDto): planungsobjekt is PlanungsobjektLinearDto {
    return planungsobjekt.discriminator === "PlanungsobjektLinear";
  }

  static isOnDemand(
    planungsobjekt: PlanungsobjektDto,
  ): planungsobjekt is PlanungsobjektOnDemandDto {
    return planungsobjekt.discriminator === "PlanungsobjektOnDemand";
  }

  static whereIs(planungsobjekt: PlanungsobjektDto): PlanungsobjektLocation {
    if (planungsobjekt.merklisteId) {
      return PlanungsobjektLocation.Merkliste;
    }

    if (planungsobjekt.planungskontext === Planungskontext.VORGESCHLAGEN) {
      return PlanungsobjektLocation.Vorschlag;
    }

    if (PlanungsobjektUtils.isOnDemand(planungsobjekt)) {
      return PlanungsobjektLocation.OnDemand;
    }

    if (
      planungsobjekt.publikationsplanung === null ||
      planungsobjekt.publikationsplanung === undefined
    ) {
      throw new Error(
        "Planungsobjekt state is invalid: Not on Merkliste and without Publikationsplanung",
      );
    }

    if (
      planungsobjekt.publikationsplanung.variante === null ||
      planungsobjekt.publikationsplanung.variante === undefined
    ) {
      return PlanungsobjektLocation.ListenKalenderAnsicht;
    }

    // Wenn eine Variante gesetzt ist, befinden wir uns auf der Blockansicht
    if (planungsobjekt.publikationsplanung.variante >= 0) {
      return PlanungsobjektLocation.Blockansicht;
    }

    throw Error("Planungsobjekt with unknown origin.");
  }

  static isOnBlockansicht(planungsobjekt: PlanungsobjektDto): boolean {
    return PlanungsobjektUtils.whereIs(planungsobjekt) === PlanungsobjektLocation.Blockansicht;
  }

  static isOnMerkliste(planungsobjekt: PlanungsobjektDto): boolean {
    return PlanungsobjektUtils.whereIs(planungsobjekt) === PlanungsobjektLocation.Merkliste;
  }

  static isOnVorschlag(planungsobjekt: PlanungsobjektDto): boolean {
    return PlanungsobjektUtils.whereIs(planungsobjekt) === PlanungsobjektLocation.Vorschlag;
  }

  static isOnListeOrKalenderansicht(planungsobjekt: PlanungsobjektDto): boolean {
    return (
      PlanungsobjektUtils.whereIs(planungsobjekt) === PlanungsobjektLocation.ListenKalenderAnsicht
    );
  }

  static isPlanungsobjekt<T extends Record<string, unknown>>(
    timeCellGroupOrPlanungsobjekt: T | PlanungsobjektDto,
  ) {
    // Typeguard für Type Intersections
    const isPlanungsobjekt = (
      planungsobjekt: PlanungsobjektDto | T,
    ): planungsobjekt is PlanungsobjektDto => {
      return (
        "id" in planungsobjekt &&
        "publikationsplanung" in planungsobjekt &&
        "merklisteId" in planungsobjekt
      );
    };
    return isPlanungsobjekt(timeCellGroupOrPlanungsobjekt)
      ? timeCellGroupOrPlanungsobjekt
      : undefined;
  }

  static hasBeziehungen(planungsobjekt: PlanungsobjektDto): boolean {
    return (
      planungsobjekt.abhaengigkeiten.length > 0 ||
      planungsobjekt.vorgaenger.length > 0 ||
      planungsobjekt.nachfolger.length > 0
    );
  }

  static updatePlanlaengeFromZeiten(vm: {
    sendetag: Date | string;
    beginnzeit: Date | number | string;
    endzeit: Date | number | string;
    prevPlanlaenge: number | null;
  }): number {
    // Transformiere Beginnzeit + Sendetag Datum in das Kalenderdatum
    // vm.beginnzeit besitzt nur die korrekte Uhrzeit das Datum entspricht allerdings Date.now()
    const beginnzeitTimeString =
      typeof vm.beginnzeit === "string"
        ? vm.beginnzeit
        : DateFnsService.formatDateAsTimeString(vm.beginnzeit);
    const beginnKalenderzeitpunkt = DateFnsService.parseDateAndTimeToDateObject(
      beginnzeitTimeString,
      vm.sendetag,
    );

    // Der Endzeit-String wurde ggf. aufgerundet, wenn die Planlänge in Sekunden nicht durch 60 teilbar ist
    // vm.endzeit besitzt nur die korrekte Uhrzeit das Datum entspricht allerdings Date.now()
    const endzeitTimeString = this.roundDownEndzeitToTimestring(vm.endzeit, vm.prevPlanlaenge);

    let endzeitKalendertag: Date;
    if (
      isSameDay(
        beginnKalenderzeitpunkt,
        vm.sendetag instanceof Date ? vm.sendetag : new Date(vm.sendetag),
      )
    ) {
      // Wenn der Sende und Kalendertag gleich sind muss überprüft werden, ob die Beginnzeit nach der Endzeit liegt
      // Falls ja muss zusätzlich 1 Tag addiert werden, um den korrekten Kalendertag für die Endzeit zu verwenden
      const liegtBeginnzeitNachEndzeit = beginnzeitTimeString > endzeitTimeString;
      endzeitKalendertag = liegtBeginnzeitNachEndzeit
        ? DateFnsService.addDays(vm.sendetag, 1)
        : vm.sendetag instanceof Date
          ? vm.sendetag
          : new Date(vm.sendetag);
    } else {
      endzeitKalendertag = beginnKalenderzeitpunkt;
    }
    // Verknüpfung von Enduhrzeit mit korrektem Kalendertag
    const endKalenderzeitpunkt = DateFnsService.parseDateAndTimeToDateObject(
      endzeitTimeString,
      endzeitKalendertag,
    );

    const calculatedLaengeInSeconds = DateFnsService.getAbsoluteSecondsDifference(
      endKalenderzeitpunkt,
      beginnKalenderzeitpunkt,
    );
    return calculatedLaengeInSeconds;
  }

  /**
   * Die Endzeit wird auf die nächste Minute aufgerundet, wenn die Planlänge in Sekunden nicht durch 60 teilbar ist.
   * Um die Planlänge korrekt berechnen zu können muss daher diese Rundung wieder rückgängig gemacht werden.
   */
  static roundDownEndzeitToTimestring(endzeit: Date | string | number, prevPlanlaenge: number | null): string {
    let endzeitTimeString =
      typeof endzeit === "string"
        ? endzeit
        : DateFnsService.formatDateAsTimeString(endzeit, "HH:mm:ss");
    if(prevPlanlaenge && prevPlanlaenge % 60 !== 0) {
      const endzeitDate = DateFnsService.parseDateAndTimeToDateObject(endzeitTimeString);
      endzeitTimeString = DateFnsService.formatDateAsTimeString(DateFnsService.addMinutes(endzeitDate, -1));
    }

    return endzeitTimeString;
  }

  static sortPlanungsobjektAscByCreatedAt = (
    planungsobjekt1: PlanungsobjektDto,
    planungsobjekt2: PlanungsobjektDto,
  ) =>
    new Date(planungsobjekt2.createdAt).getTime() - new Date(planungsobjekt1.createdAt).getTime();

  static sortPlanungsobjektAscByVon = (
    planungsobjekt1: PlanungsobjektDto,
    planungsobjekt2: PlanungsobjektDto,
  ) => {
    const sortedByVon =
      new Date(planungsobjekt1.publikationsplanung?.von ?? "").getTime() -
      new Date(planungsobjekt2.publikationsplanung?.von ?? "").getTime();
    // Fallback für den Fall, dass die Planungsobjekte keine Publikationsplanung besitzen (bspw. auf der Merkliste)
    if (!sortedByVon)
      return PlanungsobjektUtils.sortPlanungsobjektAscByCreatedAt(planungsobjekt2, planungsobjekt1);
    return sortedByVon;
  };

  /**
   * Sucht die LinearId in den Beziehungen eines OnDemand Planungsobjekts. Gibt die LinearId zurück, wenn eine Beziehung
   * vom Typ LinearOnDemand gefunden wurde. Gibt undefined zurück, wenn keine Beziehung vom Typ LinearOnDemand gefunden
   * wurde.
   *
   * @throws Error, wenn mehr als eine Beziehung vom Typ LinearOnDemand gefunden wurde.
   */
  static findLinearIdInOnDemandBeziehungen(
    planungsobjekt: PlanungsobjektOnDemandDto,
  ): string | undefined {
    const linearIds = [
      ...planungsobjekt.beziehungenEingehend,
      ...planungsobjekt.beziehungenAusgehend,
    ]
      .filter((beziehung) => beziehung.typ === "LinearOnDemand")
      .map((beziehung) =>
        beziehung.vonId === planungsobjekt.id ? beziehung.zuId : beziehung.vonId,
      );

    if (linearIds.length > 1)
      throw new Error(
        `Planungsobjekt mit der ID "${planungsobjekt.id}" hat mehr als eine Beziehung vom Typ LinearOnDemand.`,
      );

    return linearIds[0];
  }

  static isConnectedWithProductDb(planungsobjekt: Pick<PlanungsobjektDto, "getitId">): boolean {
    return planungsobjekt.getitId !== null && planungsobjekt.getitId !== "";
  }

  /**
   * Gibt das Zeitintervall für ein Planungsobjekt zurück. Das Planungsobjekt streckt sich also über diesen Zeitraum.
   * Wird in Kalendertagen berechnet, damit keine komischen Zeitverschiebungen/übergroße Ranges an den Tagesgrenzen von Sendetagen entstehen.
   */
  static getTimeRangeIntervalForPlanungsobjekt(planungsobjekt: PlanungsobjektLinearDto): Interval {
    const start = DateFnsService.parseDateAndTimeToDateObject(
      planungsobjekt.beginnzeit,
      planungsobjekt.kalendertag,
    );
    return {
      start,
      end: DateFnsService.addSeconds(start, planungsobjekt.planlaenge ?? 0),
    };
  }
}
