import { getRouterSelectors } from "@ngrx/router-store";
import { createSelector } from "@ngrx/store";
import { asArray } from "src/app/utils/array-utils";
import { getDefaultYear } from "src/app/utils/date-utils";

const { selectQueryParam, selectQueryParams, selectUrl, selectCurrentRoute } = getRouterSelectors();

const selectAnsichtIdQueryParam = createSelector(
  selectQueryParam("ansichtId"),
  (ansichtId) => ansichtId as string,
);

const selectUrlPath = createSelector(selectUrl, (url: string) => {
  if (url) {
    const parsedUrl = new URL(url, window.location.origin);
    return parsedUrl.pathname;
  }
  return "";
});

const selectKanalQueryParam = createSelector(selectQueryParam("kanal"), (kanal) => kanal);

const selectEkAnsichtFromUrlPath = createSelector(selectUrl, (url) => url.includes("ek-ansicht"));

const selectIsOnDemandFromUrlPath = createSelector(selectUrl, (url) => url.includes("vod-ansicht"));

const selectYearQueryParam = createSelector(selectQueryParam("year"), (year) => Number(year));

const selectYearFromQueryParamOrDefault = createSelector(selectYearQueryParam, (year) => {
  return year || getDefaultYear();
});

/**
 * Wählt das Jahr aus den On-Demand-Abfrageparametern aus und fällt auf das Jahr-Abfrageparameter zurück, wenn die On-Demand-Abfrageparameter nicht festgelegt sind.
 * Damit sollten alle spezifischen Routen zu Jahren auf dem Drawer abgefangen werden. Vielleicht bietet es sich perspektivisch an,
 * die Jahre in einem eigenen Store zu speichern, um die Jahre für die Drawer-Links zu verwenden.
 */
const selectYearFromAnyQueryParam = createSelector(
  selectQueryParam("von"),
  selectQueryParam("bis"),
  selectYearFromQueryParamOrDefault,
  (vonParam, bisParam, yearParam) => {
    const von = asArray(vonParam)[0];
    const bis = asArray(bisParam)[0];

    return von && bis && new Date(von).getFullYear() === new Date(bis).getFullYear()
      ? new Date(von).getFullYear()
      : yearParam;
  },
);

export default {
  selectQueryParam,
  selectQueryParams,
  selectCurrentRoute,
  selectUrl,
  selectUrlPath,
  selectAnsichtIdQueryParam,
  selectKanalQueryParam,
  selectYearQueryParam,
  selectYearFromQueryParamOrDefault,
  selectEkAnsichtFromUrlPath,
  selectIsOnDemandFromUrlPath,
  selectYearFromAnyQueryParam,
};
