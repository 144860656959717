<kendo-dropdownlist
  #dropdownList
  textField="text"
  valueField="value"
  [data]="options"
  [defaultItem]="defaultItem"
  [disabled]="isDisabledInternal || loading"
  [fillMode]="fillMode"
  [itemDisabled]="isItemDisabled"
  [listHeight]="dropdownListHeight"
  [popupSettings]="{ width: 'auto' }"
  [value]="value"
  [valuePrimitive]="true"
  (blur)="onBlur()"
  (valueChange)="onInput($event)"
>
  <ng-container *ngIf="farbgebungEnumStyleMap">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span
        class="color-indicator"
        [ngStyle]="
          dataItem?.value | colorMapToNgStyle: farbgebungEnumStyleMap : ColorMapToReturnValue.Style
        "
      ></span>
      <span [attr.data-testid]="'select-option-' + (dataItem?.value ?? 'null')">{{
        dataItem?.text
      }}</span>
    </ng-template>
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span
        class="color-indicator"
        [ngStyle]="
          dataItem?.value | colorMapToNgStyle: farbgebungEnumStyleMap : ColorMapToReturnValue.Style
        "
      ></span>
      <span [attr.data-testid]="'select-option-' + dataItem.value">{{ dataItem?.text }}</span>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isSenderPlattform">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span class="konkurrenz-sender-icon-spacer">
        <app-konkurrenzprogramm-icons
          *ngIf="dataItem?.value"
          [sender]="dataItem.value"
        ></app-konkurrenzprogramm-icons>
      </span>
      <span [attr.data-testid]="'select-option-' + (dataItem?.value ?? 'null')">{{
        dataItem?.text
      }}</span>
    </ng-template>
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span class="konkurrenz-sender-icon-spacer">
        <app-konkurrenzprogramm-icons
          *ngIf="dataItem?.value"
          [sender]="dataItem.value"
        ></app-konkurrenzprogramm-icons>
      </span>
      <span [attr.data-testid]="'select-option-' + dataItem.value">{{ dataItem?.text }}</span>
    </ng-template>
  </ng-container>
  <!-- wenn es ein "normales" Select ist -->
  <ng-container *ngIf="!farbgebungEnumStyleMap && !isSenderPlattform">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span [attr.data-testid]="'select-option-' + (dataItem?.value ?? 'null')">{{
        dataItem?.text
      }}</span>
    </ng-template>
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span [attr.data-testid]="'select-option-' + dataItem.value">{{ dataItem?.text }}</span>
    </ng-template>
  </ng-container>

  <kendo-dropdownlist-messages [noDataText]="noDataText"></kendo-dropdownlist-messages>
</kendo-dropdownlist>
