import { box, Boxed, createFormGroupState, FormGroupState } from "ngrx-forms";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";

export type SerienWindowFormData = {
  folgennummerAb: number;
  folgennummerBis: number | null;
  staffelnummer: number | null;
  anzahlFolgen: number | null;
  reihenfolgeVerlinken: boolean;
  auszulassendeDaten: Boxed<string[]>;
};

export type SerienWindowFormNgRx = FormGroupState<SerienWindowFormData>;

export const initialSerienWindowFormValue: SerienWindowFormData = {
  folgennummerAb: 1,
  folgennummerBis: null,
  staffelnummer: null,
  anzahlFolgen: null,
  reihenfolgeVerlinken: false,
  auszulassendeDaten: box([]),
};

export const serienWindowFormId = "serienWindowForm";
export const inititalSerienWindowFormState: SerienWindowFormNgRx = createFormGroupState(
  serienWindowFormId,
  initialSerienWindowFormValue,
);

export interface SerienWindowInput {
  context: AnsichtType;
  planungsobjekt: PlanungsobjektDto;
}

export interface SerienWindowResult {
  context: AnsichtType;
  params: {
    folgennummerAb: number;
    folgennummerBis: number;
    staffelnummer: number | null;
    anzahlFolgen: number | null;
    reihenfolgeVerlinken: boolean;
    planungsobjekt: PlanungsobjektDto;
    auszulassendeDaten: string[];
  };
}

export type SerienWindowFeatureState = {
  formState: SerienWindowFormNgRx;
  windowInput: SerienWindowInput | null;
};

export const initialSerienWindowFeatureState: SerienWindowFeatureState = {
  formState: inititalSerienWindowFormState,
  windowInput: null,
};
