import { createActionGroup, props } from "@ngrx/store";
import { GUID } from "src/app/models";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { CreateBeziehungAbhaengigkeitCommand } from "src/app/models/openapi/model/create-beziehung-abhaengigkeit-command";
import { CreateBeziehungReihenfolgeCommand } from "src/app/models/openapi/model/create-beziehung-reihenfolge-command";
import { Layout } from "src/app/models/openapi/model/layout";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { MovePlanungsobjektLinearToVorgeschlagenCommand } from "src/app/models/openapi/model/move-planungsobjekt-linear-to-vorgeschlagen-command";
import { MovePlanungsobjekteLinearToVorgeplantBlockCommand } from "src/app/models/openapi/model/move-planungsobjekte-linear-to-vorgeplant-block-command";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDtoResultDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto-result-dto";
import { PlanungsobjektLinearVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgemerkt-aktualisieren-command";
import { PlanungsobjektLinearVorgemerktErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgemerkt-erstellen-command";
import { PlanungsobjektLinearVorgeplantBlockAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantBlockErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-erstellen-command";
import { PlanungsobjektLinearVorgeplantBlockZuSerieUmwandelnCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-zu-serie-umwandeln-command";
import { PlanungsobjektLinearVorgeplantErstellenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-erstellen-mit-getit-command";
import { PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantSendeplatzZuSerieUmwandelnCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-zu-serie-umwandeln-command";
import { PlanungsobjektLinearVorgeschlagenAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatzCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-ersetzen-zu-vorgeplant-sendeplatz-command";
import { PlanungsobjektLinearVorgeschlagenErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-erstellen-command";
import { PlanungsobjektMitGetitSynchronisierenCommand } from "src/app/models/openapi/model/planungsobjekt-mit-getit-synchronisieren-command";
import { PlanungsobjektVerknuepfenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-verknuepfen-mit-getit-command";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { PlanungsobjekteDtoResultDto } from "src/app/models/openapi/model/planungsobjekte-dto-result-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzDtoIReadOnlyCollectionResultDto } from "src/app/models/openapi/model/sendeplatz-dto-i-read-only-collection-result-dto";
import { SendeplatzDtoResultDto } from "src/app/models/openapi/model/sendeplatz-dto-result-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";

export const planungsobjektActions = createActionGroup({
  source: "Planungsobjekt API",
  events: {
    getPlanungsobjektById: props<{
      planungsobjektId: string;
    }>(),
    getPlanungsobjekteByIds: props<{ planungsobjekteIds: string[] }>(),
    getPlanungsobjekteByIdsSuccess: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),
    getPlanungsobjektByIdSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    /**
     * Action, mit der ein {@link PlanungsobjekteDto} geladen werden kann unabhängig, ob
     * die ID von einem linearen oder on-demand Planungsobjekt stammt.
     */
    getPlanungsobjekteLinearAndOnDemandById: props<{
      planungsobjektId: string;
    }>(),
    getPlanungsobjekteLinearAndOnDemandByIdSuccess: props<{
      planungsobjekte: PlanungsobjekteDto;
    }>(),
    loadPlanungsobjekteForAnsichten: props<{
      ansichtenIds: string[];
      layout: Layout;
    }>(),
    loadPlanungsobjekteForAnsichtenSuccess: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),

    //
    // Get.it
    //
    createPlanungsobjektWithGetitId: props<{
      command: PlanungsobjektLinearVorgeplantErstellenMitGetitCommand;
    }>(),
    createPlanungsobjektWithGetitIdSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    verknuepfePlanungsobjektMitGetit: props<{
      command: PlanungsobjektVerknuepfenMitGetitCommand;
    }>(),
    verknuepfePlanungsobjektMitGetitSuccess: props<{
      planungsobjekte: PlanungsobjekteDtoResultDto;
    }>(),
    entknuepfePlanungsobjektVonGetit: props<{
      planungsobjektId: GUID;
      produktEingenschaftenBeibehalten: boolean;
    }>(),
    entknuepfePlanungsobjektVonGetitSuccess: props<{
      planungsobjekte: PlanungsobjekteDtoResultDto;
    }>(),
    synchronisierePlanungsobjektMitGetit: props<{
      command: PlanungsobjektMitGetitSynchronisierenCommand;
    }>(),
    synchronisierePlanungsobjektMitGetitSuccess: props<{
      planungsobjekte: PlanungsobjekteDtoResultDto;
    }>(),

    //
    // Creates
    //
    createPlanungsobjektOnSendeplatz: props<{
      shouldCloseWindow: boolean;
      titel: string;
      sendeplatzKey: SendeplatzKeyDto;
    }>(),
    createPlanungsobjektOnSendeplatzSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDto;
    }>(),
    createPlanungsobjektOnMerkliste: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgemerktErstellenCommand;
      merkliste: MerklisteDto;
    }>(),
    createPlanungsobjektOnMerklisteSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
      merkliste: MerklisteDto;
    }>(),
    createPlanungsobjektLinearVorgeschlagen: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgeschlagenErstellenCommand;
    }>(),
    createPlanungsobjektLinearVorgeschlagenSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    createPlanungsobjektOnBlockansicht: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    /**
     * In Zukunft sollte es nur noch diese Action geben, wenn wir das alte Fenster abschaffen.
     * createPlanungsobjektOnBlockansicht wird dann nichtmehr benötigt.
     */
    createPlanungsobjektOnBlockansichtNewWindow: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgeplantBlockErstellenCommand;
    }>(),
    createPlanungsobjektOnBlockansichtSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    // Die properties sendeplatz und oldPlanungsobjekt werden für die Duration-Funktion benötigt
    updatePlanungsobjektOnSendeplatz: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand;
      sendeplatz: SendeplatzDto;
      oldPlanungsobjekt: PlanungsobjektDto;
    }>(),
    /**
     * In Zukunft sollte es nur noch diese Action geben, wenn wir das alte Fenster abschaffen.
     * Problematisch mit dem UnifyDuration Dialog, weil wir dafür den Sendeplatz/altes PO brauchen.
     * Den wollen wir aber nicht durch das ganze neue Fenster schleifen
     */
    updatePlanungsobjektOnSendeplatzNewWindow: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand;
    }>(),
    updatePlanungsobjektOnSendeplatzSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    updatePlanungsobjektLinearVorgeschlagen: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgeschlagenAktualisierenCommand;
    }>(),
    updatePlanungsobjektLinearVorgeschlagenSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    updatePlanungsobjektOnMerkliste: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgemerktAktualisierenCommand;
    }>(),
    updatePlanungsobjektOnMerklisteSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    updatePlanungsobjektOnBlockansicht: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    /**
     * In Zukunft sollte es nur noch diese Action geben, wenn wir das alte Fenster abschaffen.
     * updatePlanungsobjektOnBlockansicht wird dann nichtmehr benötigt.
     * TODO: Ist jetzt schon passiert, updatePlanungsobjektOnBlockansicht kann also gelöscht und
     * diese Action umbenannt werden.
     */
    updatePlanungsobjektOnBlockansichtNewWindow: props<{
      shouldCloseWindow: boolean;
      command: PlanungsobjektLinearVorgeplantBlockAktualisierenCommand;
    }>(),
    updatePlanungsobjektOnBlockansichtSuccess: props<{
      shouldCloseWindow: boolean;
      planungsobjekt: PlanungsobjektLinearDtoResultDto;
    }>(),
    deletePlanungsobjekt: props<{
      planungsobjektId: GUID;
    }>(),
    deletePlanungsobjektSuccess: props<{
      planungsobjektId: GUID;
    }>(),
    deleteMultiplePlanungsobjekte: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),
    deleteMultiplePlanungsobjekteSuccess: props<{
      planungsobjekteIds: string[];
      shouldRealodMerklisten: boolean;
    }>(),
    deletePlanungsobjektOnMerkliste: props<{
      planungsobjektId: GUID;
    }>(),
    copyPlanungsobjekt: props<{
      planungsobjekt: PlanungsobjektDto;
      copyPattern: CopyPattern;
    }>(),
    copyPlanungsobjektSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDtoResultDto;
    }>(),
    copyPlanungsobjektOnMerkliste: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    copyPlanungsobjektOnMerklisteSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    copyPlanungsobjektOnBlockansicht: props<{
      planungsobjektId: string;
      copyPattern: CopyPattern;
    }>(),
    copyPlanungsobjektOnBlockansichtSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    assignPlanungsobjektToMengengeruest: props<{
      // Sendeplatz wird hier für den Aufruf der Methode openUnifyDurationDialog$ im Duration Service benötigt
      // Für die Blockansicht exisistert kein Sendeplatz und es wird stattdessen undefined zurückgeliefert
      sendeplatz?: SendeplatzDto;
      planungsobjekt: PlanungsobjektDto;
      mengengeruesteintragId?: string | null | undefined;
    }>(),
    assignPlanungsobjektToMengengeruestSuccess: props<{
      // Sendeplatz wird hier für den Aufruf der Methode openUnifyDurationDialog$ im Duration Service benötigt
      // Für die Blockansicht exisistert kein Sendeplatz und es wird stattdessen undefined zurückgeliefert
      sendeplatz?: SendeplatzDto;
      planungsobjekt: PlanungsobjektDto;
      mengengeruesteintragId?: string | null | undefined;
    }>(),
    movePlanungsobjekt: props<{
      planungsobjektId: string;
      sendeplatz: SendeplatzDto;
    }>(),
    movePlanungsobjektSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDtoResultDto;
    }>(),
    movePlanungsobjektToMerkliste: props<{
      planungsobjektId: GUID;
      merkliste: MerklisteDto;
    }>(),
    movePlanungsobjektToMerklisteSuccess: props<{
      planungsobjekt: PlanungsobjektLinearDtoResultDto;
      merkliste: MerklisteDto;
    }>(),
    movePlanungsobjektInBlockansicht: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    // Die fehlende AnsichtId wird im Effekt aus den routerSelectors geholt
    moveMultiplePlanungsobjektInBlockansicht: props<{
      command: Omit<MovePlanungsobjekteLinearToVorgeplantBlockCommand, "ansichtId">;
    }>(),
    moveMultiplePlanungsobjektInBlockansichtSuccess: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),

    movePlanungsobjektInBlockansichtSuccess: props<{
      planungsobjekt: PlanungsobjektLinearDtoResultDto;
    }>(),
    movePlanungsobjektToVorschlagspalte: props<{
      command: MovePlanungsobjektLinearToVorgeschlagenCommand;
    }>(),
    movePlanungsobjektToVorschlagspalteSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDto;
    }>(),
    moveMultiplePlanungsobjekte: props<{
      command: { planungsobjekte: PlanungsobjektDto[]; daysOffset: number };
    }>(),
    moveMultiplePlanungsobjekteSuccess: props<{
      planungsobjekte: PlanungsobjektDto[];
      daysOffset: number;
      sendeplaetze: SendeplatzDtoIReadOnlyCollectionResultDto;
    }>(),
    planungsobjektZuSerieUmwandelnSendeplatz: props<{
      planungsobjekt: PlanungsobjektDto;
      command: PlanungsobjektLinearVorgeplantSendeplatzZuSerieUmwandelnCommand;
    }>(),
    planungsobjektZuSerieUmwandelnSendeplatzSuccess: props<{
      planungsobjektId: string;
      newPlanungsobjekte: PlanungsobjektDto[];
      sendeplaetze: SendeplatzDto[];
    }>(),
    planungsobjektZuSerieUmwandelnBlock: props<{
      planungsobjekt: PlanungsobjektDto;
      command: PlanungsobjektLinearVorgeplantBlockZuSerieUmwandelnCommand;
    }>(),
    planungsobjektZuSerieUmwandelnBlockSuccess: props<{
      planungsobjektId: string;
      newPlanungsobjekte: PlanungsobjektDto[];
    }>(),
    upsertPlanungsobjekteFromMerklistenSuccess: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),
    upsertPlanungsobjekteByAnsichtenSuccess: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),
    setAllPlanungsobjekte: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),

    movePlanungsobjektVorgeschlagenToVorgeplant: props<{
      planungsobjektId: string;
      sendeplatz: SendeplatzDto; // Wird für duration service benötigt
    }>(),
    movePlanungsobjektVorgeschlagenToVorgeplantSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDto; // Wird für duration service benötigt
    }>(),
    replacePlanungsobjektWithVorschlag: props<{
      command: PlanungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatzCommand;
      sendeplatz: SendeplatzDto; // Wird für duration service benötigt
    }>(),
    replacePlanungsobjektWithVorschlagSuccess: props<{
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDto; // Wird für duration service benötigt
    }>(),
    selectPlanungsobjektInAnsicht: props<{ planungsobjektId: string }>(),
    deselectPlanungsobjektInAnsicht: props<{ planungsobjektId?: string }>(),
    upsertPlanungsobjekteForBlockansicht: props<{
      planungsobjekte: PlanungsobjektDto[];
    }>(),

    // TODO: Sollte eventuell zu planungsobjektbeziehung.actions verschoben werden.
    createReihenfolgeForSelectedPlanungsobjekte: props<{
      command: CreateBeziehungReihenfolgeCommand;
    }>(),

    createAbhaengigkeitForSelectedPlanungsobjekte: props<{
      command: CreateBeziehungAbhaengigkeitCommand;
    }>(),
  },
});
