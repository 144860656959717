import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { serienWindowActions } from "./serien-window.actions";
import { serienWindowFormReducer } from "./serien-window.form";
import { SerienWindowFeatureState, initialSerienWindowFeatureState } from "./serien-window.model";

const serienWindowReducer = createReducer(
  initialSerienWindowFeatureState,
  on(
    serienWindowActions.openWindow,
    (_, windowInput): SerienWindowFeatureState => ({
      ...initialSerienWindowFeatureState,
      windowInput,
    }),
  ),
  onNgrxForms(),
);

const reducer = mergeReducers(serienWindowReducer, serienWindowFormReducer);

export const serienWindowFeauture = createFeature({
  name: "serienWindow",
  reducer,
});
