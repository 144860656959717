import { Component, inject } from "@angular/core";
import { Boxed, NgrxValueConverter, box } from "ngrx-forms";
import { DateFnsService } from "src/app/services/date-fns.service";
import { SerienWindowFacade } from "./serien-window.facade";

const valueConverter: NgrxValueConverter<Date[], Boxed<string[]>> = {
  convertViewToStateValue(value: Date[]): Boxed<string[]> {
    return box(value.map((date) => DateFnsService.formatDateAsString(date)));
  },
  convertStateToViewValue(value: Boxed<string[]>): Date[] {
    return value.value.map((date) => DateFnsService.parseStringToDate(date));
  },
};

@Component({
  selector: "app-serien-window",
  templateUrl: "./serien-window.component.html",
  styleUrls: ["./serien-window.component.scss"],
  providers: [SerienWindowFacade],
})
export class SerienWindowComponent {
  public readonly facade = inject(SerienWindowFacade);

  public readonly valueConverter = valueConverter;

  enableAuszulassendeDaten = false;
  focusedDate: Date;

  onEnableAuszulassendeDatenChange(isEnabled: boolean) {
    if (!isEnabled) {
      this.facade.removeAuszulassendeDaten();
    }
  }

  onClose() {
    this.facade.closeWindow();
  }

  onSave() {
    this.facade.save();
  }
}
