import { addDays, subDays } from "date-fns";
import { Kanal } from "../models/openapi/model/kanal";
import { Wochentag } from "../models/openapi/model/wochentag";
import { DateFnsService } from "../services/date-fns.service";

export const wochentagToDayOfWeek: Record<Wochentag, number> = {
  Sonntag: 0,
  Montag: 1,
  Dienstag: 2,
  Mittwoch: 3,
  Donnerstag: 4,
  Freitag: 5,
  Samstag: 6,
};

// Fest definierte Sortierungsreihenfolge der Wochentage in den Listenansichten
export const wochentagProgrammwocheOrder: Record<Wochentag, number> = {
  Samstag: 0,
  Sonntag: 1,
  Montag: 2,
  Dienstag: 3,
  Mittwoch: 4,
  Donnerstag: 5,
  Freitag: 6,
};

// Fest definierte Sortierungsreihenfolge der Wochentage in den Kalenderansichten
export const wochentagIsoOrder: Record<Wochentag, number> = {
  Montag: 0,
  Dienstag: 1,
  Mittwoch: 2,
  Donnerstag: 3,
  Freitag: 4,
  Samstag: 5,
  Sonntag: 6,
};

/**
 * Definition der Tagesgrenzen gemäß: https://jira.zdf.de/jira/browse/PUBLIT-296
 */
export const kanalTagesgrenzen = {
  ZDF: "05:25",
  ZDFInfo: "05:00",
  ZDFNeo: "06:00",
  DreiSat: "05:55",
  ZDFMediathek: undefined,
  Unbekannt: undefined,
} satisfies Record<Kanal, string | undefined>;

/**
 * --- Disclaimer: Die Klasse wurden anhand der Backend Implementierung für TypeScript migriert. ---
 * Führt verschiedene kanalabhängige Datums- und Zeitberechnungen durch.
 * Zur Prüfung ob ein Tag vor oder hinter einer Tagesgrenze liegt sollten
 * immer die Methoden "IsBetweenMidnightToTagesgrenze" bzw.
 * "IsBetweenTagesgrenzeToMidnight" verwendet werden, damit es
 * zu keinen Problemem im Grenzbereich (inklusive <= =>, exklusive < >) kommt.
 */
export class KanalOffsetUtils {
  /**
   * Erstellt aus einem DateOnly ein DateTime Objekt mit dem Beginn der Tagesgrenze des gegebenen Kanals.
   * Der Sendetag beginnt beispielseweise im ZDF erst um 5:25 Uhr des Kalendertags.
   */
  public static adjustKalendertagVon(von: string, kanal: Kanal): Date {
    const time = this.getTagesgrenze(kanal);
    return DateFnsService.parseDateAndTimeToDateObject(time, von);
  }

  /**
   * Erstellt aus einem DateOnly ein DateTime Objekt mit dem Ende der Tagesgrenze des gegebenen Kanals.
   * Der Sendetag endet beispielseweise im ZDF erst um 5:30 Uhr am nächsten (!) Kalendertag.
   */
  public static adjustKalendertagBis(bis: string, kanal: Kanal): Date {
    const time = this.getTagesgrenze(kanal);
    return DateFnsService.addDays(DateFnsService.parseDateAndTimeToDateObject(time, bis), 1);
  }

  /**
   * Liegt die gegebene Zeit im "großen Zeitfenster" von Beginn des Sendetages (Tagesgrenze) bis Mitternacht.
   * Für den Kanal ZDF von 5.25 (inklusive) bis 23.59 (inklusive) Uhr.
   */
  public static isBetweenTagesgrenzeToMidnight(time: string, kanal: Kanal) {
    return time >= this.getTagesgrenze(kanal);
  }

  /**
   * Liegt die gegebene Zeit im "kleinen Zeitfenster" von Mitternach bis zum beginn des Sendetages (Tagesgrenze).
   * Für den Kanal ZDF von 0.00 (inklusive) bis 5.25 (exklusive) Uhr
   */
  public static isBetweenMidnightToTagesgrenze(time: string, kanal: Kanal) {
    return time < this.getTagesgrenze(kanal);
  }

  /**
   * Verschiebt den Wochentag abhängig von der Tagesgrenze des gegebenen Kanals und der Uhrzeit.
   * Der Sendewochentag liegt dadurch möglicherweise am Vortag.
   */
  public static getSendewochentag(wochentag: Wochentag, time: string, kanal: Kanal): Wochentag {
    if (this.isBetweenMidnightToTagesgrenze(time, kanal)) {
      return this.getYesterday(wochentag);
    }
    return wochentag;
  }

  /**
   * Verschiebt das Datum abhängig von der Tagesgrenze des gegebenen Kanals und der Uhrzeit.
   * Der Sendetag liegt dadurch möglicherweise am kalendarischen Vortag.
   */
  public static getSendetag(kalendertag: Date, time: string, kanal: Kanal): Date {
    if (this.isBetweenMidnightToTagesgrenze(time, kanal)) {
      return subDays(kalendertag, 1);
    }
    return kalendertag;
  }

  /**
   * Verschiebt das Datum abhängig von der Tagesgrenze des gegebenen Kanals und der Uhrzeit.
   * Der Kalendertag liegt dadurch möglicherweise ein Tag nach dem Sendetag.
   */
  public static getKalendertagForSendetag(sendetag: Date, time: string, kanal: Kanal): Date {
    if (this.isBetweenMidnightToTagesgrenze(time, kanal)) {
      return addDays(sendetag, 1);
    }
    // Ist bereits der korrekte Kalendertag
    return sendetag;
  }

  public static getSendetagBeginnzeitForKalenderDatum(
    date: Date,
    kanal: Kanal,
  ): {
    sendetag: string;
    beginnzeit: string;
  } {
    const isBetweenMidnightToTagesgrenze = this.isBetweenMidnightToTagesgrenze(
      `${date.getHours()}:${date.getMinutes()}`,
      kanal,
    );
    const compareDate = new Date(date.getTime());
    compareDate.setHours(date.getHours());
    compareDate.setMinutes(date.getMinutes());
    if (isBetweenMidnightToTagesgrenze) {
      compareDate.setDate(compareDate.getDate() - 1);
    }

    return {
      sendetag: DateFnsService.formatDateAsString(compareDate),
      beginnzeit: DateFnsService.formatDateAsTimeString(compareDate),
    };
  }

  public static transformSendezeitpunktToKalenderzeitpunkt(
    sendeZeitpunkt: Date | undefined | null,
    kanal: Kanal,
  ): Date {
    if (!sendeZeitpunkt || sendeZeitpunkt.toString() === "Invalid Date") {
      throw new Error("Received invalid date");
    }
    const kalenderZeitpunkt = new Date(sendeZeitpunkt.getTime());
    if (
      this.isBetweenMidnightToTagesgrenze(
        DateFnsService.formatDateAsTimeString(kalenderZeitpunkt),
        kanal,
      )
    ) {
      kalenderZeitpunkt.setDate(kalenderZeitpunkt.getDate() + 1);
    }
    return kalenderZeitpunkt;
  }

  /**
   * Liegt die gegebene Zeit noch im "kleinen Zeitfenster" von Mitternach bis zum Beginn des Sendetages (Tagesgrenze)
   * (Für den Kanal ZDF von 0.00 (inklusive) bis 5.25 (exklusive) Uhr),
   * dann wird das Datum auf den nächsten, eigentlich tatsächlichen, Tag gesetzt
   *
   * @param dateTime Datum und Uhrzeit
   * @returns Angepasstes Datum mit unveränderter Uhrzeit
   */
  public static getDateWithTagesgrenze(dateTime: Date, kanal: Kanal, reverse?: boolean): Date {
    return DateFnsService.formatDateAsTimeString(dateTime) <= this.getTagesgrenze(kanal)
      ? DateFnsService.addDays(dateTime, reverse ? -1 : 1)
      : dateTime;
  }

  /**
   * Definition der Tagesgrenzen gemäß: https://jira.zdf.de/jira/browse/PUBLIT-296
   */
  private static getTagesgrenze(kanal: Kanal) {
    const _kanal = kanalTagesgrenzen[kanal];
    if (!_kanal) {
      throw new Error(
        `Die Tagesgrenze kann nicht ermittelt werden, da der Kanal ${kanal} nicht existiert oder keine Tagesgrenze besitzt.`,
      );
    }
    return _kanal;
  }

  /**
   * Gibt den Vortag zu einem gegebenen Wochentag zurück.
   */
  private static getYesterday(wochentag: Wochentag) {
    switch (wochentag) {
      case Wochentag.MONTAG:
        return Wochentag.SONNTAG;
      case Wochentag.DIENSTAG:
        return Wochentag.MONTAG;
      case Wochentag.MITTWOCH:
        return Wochentag.DIENSTAG;
      case Wochentag.DONNERSTAG:
        return Wochentag.MITTWOCH;
      case Wochentag.FREITAG:
        return Wochentag.DONNERSTAG;
      case Wochentag.SAMSTAG:
        return Wochentag.FREITAG;
      case Wochentag.SONNTAG:
        return Wochentag.SAMSTAG;
      default:
        throw new Error(
          `Der Sendetag kann nicht ermittelt werden, da der Wochentag ${wochentag} nicht existiert.`,
        );
    }
  }

  /**
   * Wenn die Beginnzeit vor der Tagesgrenze des gegebenen Kanals liegt,
   * ist der (ZDF)Sendetag am Vortag des Kalendertages.
   */
  public static calculateKalendertagAsString(
    sendetag: Date | string,
    beginnzeit: string,
    kanal: Kanal,
  ): string {
    const sendezeitpunkt = DateFnsService.parseDateAndTimeToDateObject(beginnzeit, sendetag);
    const kalendertag = this.transformSendezeitpunktToKalenderzeitpunkt(sendezeitpunkt, kanal);
    return DateFnsService.formatDateAsString(kalendertag);
  }

  /**
   * Stellt Wochentage über Indizes bereit.
   * getWochentag[0] -> Montag, getWochentag[6] -> Sonntag
   */
  public static getWochentag = [
    Wochentag.MONTAG,
    Wochentag.DIENSTAG,
    Wochentag.MITTWOCH,
    Wochentag.DONNERSTAG,
    Wochentag.FREITAG,
    Wochentag.SAMSTAG,
    Wochentag.SONNTAG,
  ];

  /**
   * Schemaplatz Kalendertag errechnen. Für den Fall das wir den tatsächlichen Kalendertag ermitteln müssen, u.a. für Mengengerüste auf der Blockansicht.
   * Generell wenn die Beginnzeit nicht mit der Beginnzeit im Schemaplatz übereinstimmt.
   */
  public static calculateSchemaKalendertag(
    schemaWochentag: Wochentag,
    schemaBeginnzeit: string,
    beginnzeit: string,
  ) {
    const isBetweenMidnightToSchemaBeginnzeit = beginnzeit < schemaBeginnzeit;
    if (isBetweenMidnightToSchemaBeginnzeit) {
      //getTomorrow als Wochentag quasi
      const wochentagIndex = this.getWochentag.indexOf(schemaWochentag);
      if (wochentagIndex < 0) {
        console.error("Wochentag nicht gelistet");
      }
      return this.getWochentag[(wochentagIndex + 1) % this.getWochentag.length];
    }
    return schemaWochentag;
  }

  /**
   * Wandle einen technischen Enum Kanal in einen lesbaren String um (z.B. ZDFNeo -> "ZDFneo")
   */
  public static kanalToReadableString(kanal: Kanal): string {
    switch (kanal) {
      case Kanal.ZDF:
        return "ZDF";
      case Kanal.ZDF_INFO:
        return "ZDFinfo";
      case Kanal.ZDF_NEO:
        return "ZDFneo";
      case Kanal.DREI_SAT:
        return "3sat";
      case Kanal.ZDF_MEDIATHEK:
        return "ZDFmediathek";
      default:
        return "Unbekannt";
    }
  }

  public static getSendetagdefinitionenForKanal(kanal: Kanal): string {
    const unbekannterKanal = "Der Sendetag für diesen Ausspielweg ist unbekannt.";
    return kanal === Kanal.UNBEKANNT
      ? unbekannterKanal
      : "Der Sendetag für diesen Ausspielweg beginnt um " +
          (kanalTagesgrenzen[kanal] ?? "0:00") +
          " Uhr.";
  }
}
