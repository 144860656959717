import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { planungsobjektFarbgebungMap } from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import {
  ColorMapToNgStylePipe,
  ColorMapToReturnValue,
} from "./color-map-to-ng-style/color-map-to-ng-style.pipe";

@Pipe({
  name: "planungsobjektStyle",
})
export class PlanungsobjektStylePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(planungsobjekt: PlanungsobjektDto) {
    const { background, color, borderColor } = ColorMapToNgStylePipe.prototype.transform(
      planungsobjekt.farbgebung,
      planungsobjektFarbgebungMap,
      ColorMapToReturnValue.Style,
    );

    const defaultVpStyle = { background, color, "z-index": "1", position: "relative" };

    return this.store
      .select(planungsobjektSelectors.selectPlanungsobjektStyles(planungsobjekt.id, borderColor))
      .pipe(
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((planungsobjektStyles) => ({
          ...defaultVpStyle,
          ...planungsobjektStyles,
        })),
      );
  }
}
