import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";

export const serienWindowActions = createActionGroup({
  source: "Serien Window",
  events: {
    openWindow: props<{
      context: AnsichtType;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    tryClosingWithoutSaving: emptyProps(),
    closeWindow: emptyProps(),
    windowClosed: emptyProps(),
  },
});
