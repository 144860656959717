import { AbstractControl } from "@angular/forms";
import { AnyObject } from "./object-utils";

/**
 * Type der bei Implementierung von ControlValueAccessor für die onTouched Methode verwendet werden kann
 */
export type OnTouchedFn = () => void;

/**
 * Type der bei Implementierung von ControlValueAccessor für die onChange Methode verwendet werden kann
 */
export type OnChangeFn<T> = (value: T) => void;

/**
 * Enablen oder Disablen eines Controls
 * @param control das Control, das enabled/disabled werden soll
 * @param enabled `true` wenn das Control enabled werden soll, `false` wenn es disabled werden soll
 */
export const setEnablementForControl = (
  control: AbstractControl | undefined,
  enabled: boolean | null,
) => {
  // enabled === null soll dafür sorgen, dass wir disablen/enablen den Kindern überlassen
  if (!control || enabled === null) {
    return;
  }
  if (enabled) {
    control.enable();
  } else {
    control.disable();
  }
};

/**
 * Zieht den Typen einer FormGroup aus der FormGroup raus
 */
export type FormGroupType<T> = T extends AbstractControl<infer U> ? U : never;

/**
 * Literal Type für die Steuerung des Enablements von FormControls
 * * `enable` - Control wird enabled
 * * `disable` - Control wird disabled
 * * `no-change` - Control wird nicht verändert
 */
export type ControlEnablement = "enable" | "disable" | "no-change";

/**
 * Type, der für jedes Property im Objekt `T` das Enablement steuert
 */
export type EnablementForKeysOf<T extends AnyObject> = {
  [K in keyof T]?: ControlEnablement;
};

export type RawFormValue<T extends AbstractControl> = ReturnType<T["getRawValue"]>;
