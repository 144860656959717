import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { AktualisierenPlanungsobjekteOnDemandPlanungskontextCommand } from "src/app/models/openapi/model/aktualisieren-planungsobjekte-on-demand-planungskontext-command";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { PlanungsobjektOnDemandDtoIEnumerableResultDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto-i-enumerable-result-dto";
import { PlanungsobjektOnDemandVerschiebenZuVorgemerktCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-verschieben-zu-vorgemerkt-command";
import { PlanungsobjektOnDemandVerschiebenZuVorgeplantCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-verschieben-zu-vorgeplant-command";
import { PlanungsobjektOnDemandVerschiebenZuVorgeschlagenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-verschieben-zu-vorgeschlagen-command";
import { PlanungsobjektOnDemandVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgemerkt-aktualisieren-command";
import { PlanungsobjektOnDemandVorgemerktErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgemerkt-erstellen-command";
import { PlanungsobjektOnDemandVorgeplantAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeplant-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeplantErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeplant-erstellen-command";
import { PlanungsobjektOnDemandVorgeschlagenAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeschlagenErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeschlagen-erstellen-command";

export const onDemandActions = createActionGroup({
  source: "OnDemand",
  events: {
    getPlanungsobjektOnDemandById: props<{ planungsobjektOnDemandId: string }>(),
    getPlanungsobjektOnDemandByIdSuccess: props<{
      planungsobjektOnDemand: PlanungsobjektOnDemandDto;
    }>(),
    getPlanungsobjektOnDemandByIdFailure: props<{ error: unknown }>(),
    loadOnDemand: emptyProps(),
    loadOnDemandSuccess: props<{
      planungsobjekteOnDemand: PlanungsobjektOnDemandDto[];
      planungsobjekteLinear: PlanungsobjektLinearDto[];
    }>(),
    loadOnDemandFailure: props<{ error: unknown }>(),
    createOnDemandVorgeplant: props<{
      value: PlanungsobjektOnDemandVorgeplantErstellenCommand;
      shouldCloseWindow: boolean;
    }>(),
    createOnDemandVorgeschlagen: props<{
      value: PlanungsobjektOnDemandVorgeschlagenErstellenCommand;
      shouldCloseWindow: boolean;
    }>(),
    createOnDemandSuccess: props<{
      planungsobjekt: PlanungsobjektOnDemandDto;
      shouldCloseWindow: boolean;
    }>(),
    createOnDemandFailure: props<{ error: unknown }>(),
    updateOnDemandVorgeplant: props<{
      value: PlanungsobjektOnDemandVorgeplantAktualisierenCommand;
      shouldCloseWindow: boolean;
    }>(),
    updateOnDemandVorgeschlagen: props<{
      value: PlanungsobjektOnDemandVorgeschlagenAktualisierenCommand;
      shouldCloseWindow: boolean;
    }>(),
    updateOnDemandVorgemerkt: props<{
      value: PlanungsobjektOnDemandVorgemerktAktualisierenCommand;
      shouldCloseWindow: boolean;
    }>(),
    updateOnDemandFailure: props<{ error: unknown }>(),
    updateOnDemandSuccess: props<{
      planungsobjekt: PlanungsobjektOnDemandDto;
      shouldCloseWindow: boolean;
    }>(),
    updateOnDemandPlanungskontext: props<{
      command: AktualisierenPlanungsobjekteOnDemandPlanungskontextCommand;
    }>(),
    updateOnDemandPlanungskontextSuccess: props<{
      onDemandPlanungsobjekte: PlanungsobjektOnDemandDtoIEnumerableResultDto;
    }>(),

    /**
     * Verschiebt ein Planungsobjekt OnDemand in den Kontext "Vorgeschlagen".
     */
    verschiebePlanungsobjektOnDemandZuVorgeschlagen: props<{
      command: PlanungsobjektOnDemandVerschiebenZuVorgeschlagenCommand;
    }>(),
    verschiebePlanungsobjektOnDemandZuVorgeschlagenSuccess: props<{
      planungsobjektOnDemand: PlanungsobjektOnDemandDto;
    }>(),

    /**
     * Verschiebt ein Planungsobjekt OnDemand in den Kontext "Vorgeplant".
     */
    verschiebePlanungsobjektOnDemandZuVorgeplant: props<{
      command: PlanungsobjektOnDemandVerschiebenZuVorgeplantCommand;
    }>(),
    verschiebePlanungsobjektOnDemandZuVorgeplantSuccess: props<{
      planungsobjektOnDemand: PlanungsobjektOnDemandDto;
    }>(),

    /**
     * Ein neues Planungsobjekt OnDemand auf der Merkliste erstellen.
     */
    createPlanungsobjektOnDemandVorgemerkt: props<{
      command: PlanungsobjektOnDemandVorgemerktErstellenCommand;
      merkliste: MerklisteDto;
    }>(),
    createPlanungsobjektOnDemandVorgemerktSuccess: props<{
      planungsobjektOnDemand: PlanungsobjektOnDemandDto;
      merkliste: MerklisteDto;
    }>(),

    /**
     * Ein Planungsobjekt OnDemand auf die Merkliste verschieben.
     * Entweder von einem anderen Planungskontext oder zwischen zwei Merklisten.
     */
    verschiebePlanungsobjektOnDemandZuVorgemerkt: props<{
      command: PlanungsobjektOnDemandVerschiebenZuVorgemerktCommand;
      merkliste: MerklisteDto;
    }>(),
    verschiebePlanungsobjektOnDemandZuVorgemerktSuccess: props<{
      planungsobjektOnDemand: PlanungsobjektOnDemandDto;
      merkliste: MerklisteDto;
    }>(),
  },
});
