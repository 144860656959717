<ng-container *ngIf="label">
  <app-input-label
    [angularControl]="angularControl"
    [control]="controlState"
    [for]="for"
    [hint]="hint"
    [loading]="loading"
    [text]="label"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <app-form-field-error
      *ngIf="showErrors"
      class="k-mr-2"
      showAfterLabel
      [validationErrors]="controlState.errors"
    ></app-form-field-error>
  </app-input-label>
</ng-container>

<ng-container *ngIf="!label">
  <!-- ohne Label wird ValidationError Icon im Input angezeigt -->
  <div class="k-relative">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <app-form-field-error
      *ngIf="showErrors"
      class="k-absolute k-right-2 k-top-1.5 k-z-10"
      [validationErrors]="controlState.errors"
    ></app-form-field-error>
  </div>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
