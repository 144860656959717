import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { merklisteActions } from "../merkliste/merkliste.actions";
import { onDemandBeziehungFormActions } from "../planungsobjekt-window/on-demand-form/on-demand-beziehung-form.actions";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { onDemandActions } from "./on-demand.actions";

export type OnDemandState = EntityState<PlanungsobjektOnDemandDto> & {
  loading: boolean;
};

const adapter = createEntityAdapter<PlanungsobjektOnDemandDto>();

export const initialOnDemandState: OnDemandState = adapter.getInitialState({
  loading: false,
});

export const onDemandFeature = createFeature({
  name: "OnDemand",
  reducer: createReducer(
    initialOnDemandState,
    on(
      onDemandActions.getPlanungsobjektOnDemandByIdSuccess,
      (state, { planungsobjektOnDemand }): OnDemandState => {
        return adapter.upsertOne(planungsobjektOnDemand, state);
      },
    ),
    on(onDemandActions.loadOnDemand, (state): OnDemandState => ({ ...state, loading: true })),
    on(onDemandActions.loadOnDemandSuccess, (state, action): OnDemandState => {
      // PUBLIT-1488 / Hier stand zuvor ein setAll, dadurch könnte der Store jedoch nicht für die
      // OnDemand Merklisten verwendet werden. Daher werden nun stattdessen alle Planungsobjekte entfernt,
      // die nicht mit einer Merkliste verknüpft sind und das Ergebnis der loadAction dann über addMany hinzugefügt.
      // So bleiben die Planungsobjekte für die Merklisten erhalten und werden nicht überschrieben.
      // return adapter.setAll(action.planungsobjekteOnDemand, {
      //   ...state,
      //   loading: false,
      // });
      state = adapter.removeMany(
        (planungsobjektOnDemand) => planungsobjektOnDemand.merklisteId === null,
        state,
      );
      state = adapter.addMany(action.planungsobjekteOnDemand, { ...state, loading: false });
      return state;
    }),
    on(
      onDemandActions.updateOnDemandPlanungskontextSuccess,
      (state, { onDemandPlanungsobjekte }): OnDemandState => {
        return adapter.upsertMany(onDemandPlanungsobjekte.result, {
          ...state,
          loading: false,
        });
      },
    ),
    on(
      onDemandActions.createOnDemandVorgeplant,
      onDemandActions.updateOnDemandVorgeplant,
      onDemandActions.createOnDemandVorgeschlagen,
      onDemandActions.updateOnDemandVorgeschlagen,
      (state): OnDemandState => ({ ...state, loading: true }),
    ),
    on(
      onDemandActions.createOnDemandSuccess,
      (state, { planungsobjekt }): OnDemandState => ({
        ...adapter.addOne(planungsobjekt, state),
        loading: false,
      }),
    ),
    on(
      onDemandActions.updateOnDemandSuccess,
      (state, { planungsobjekt }): OnDemandState => ({
        ...adapter.upsertOne(planungsobjekt, state),
        loading: false,
      }),
    ),
    on(
      planungsobjektActions.deletePlanungsobjektSuccess,
      (state, { planungsobjektId }): OnDemandState => ({
        ...adapter.removeOne(planungsobjektId, state),
        loading: false,
      }),
    ),
    on(
      onDemandActions.verschiebePlanungsobjektOnDemandZuVorgeplantSuccess,
      onDemandActions.verschiebePlanungsobjektOnDemandZuVorgeschlagenSuccess,
      (state, { planungsobjektOnDemand }): OnDemandState => {
        return adapter.upsertOne(planungsobjektOnDemand, state);
      },
    ),
    /**
     * Fügt alle Planungsobjekte OnDemand beim Laden der Merklisten in den State hinzu.
     */
    on(merklisteActions.loadMerklistenSuccess, (state, { merklisten }): OnDemandState => {
      const planungsobjekteOnDemand = merklisten.flatMap(
        (merkliste) => merkliste.planungsobjekteOnDemand,
      );
      return adapter.upsertMany(planungsobjekteOnDemand, state);
    }),
    on(
      onDemandActions.createPlanungsobjektOnDemandVorgemerktSuccess,
      (state, { planungsobjektOnDemand, merkliste: _ }): OnDemandState => {
        return adapter.upsertOne(planungsobjektOnDemand, state);
      },
    ),
    on(
      onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerktSuccess,
      (state, { planungsobjektOnDemand, merkliste: _ }): OnDemandState => {
        return adapter.upsertOne(planungsobjektOnDemand, state);
      },
    ),
    on(
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
      planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess,
      (state, { planungsobjekte }): OnDemandState => {
        return adapter.upsertMany(planungsobjekte.onDemand, state);
      },
    ),
    on(
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenSuccess,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenSuccess,
      (state, { planungsobjekte }): OnDemandState => {
        return adapter.upsertOne(planungsobjekte.onDemand[0], state);
      },
    ),
  ),
  extraSelectors: ({ selectOnDemandState }) => ({
    ...adapter.getSelectors(selectOnDemandState),
  }),
});
