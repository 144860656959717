import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PlanungsobjektWindowOnDemandFormData } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { GUID } from "src/app/models";
import { LinearOnDemandBeziehungVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgemerkt-aktualisieren-command";
import { LinearOnDemandBeziehungVorgemerktErstellenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgemerkt-erstellen-command";
import { LinearOnDemandBeziehungVorgeschlagenVorgeplantErstellenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgeschlagen-vorgeplant-erstellen-command";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";

export const onDemandBeziehungFormActions = createActionGroup({
  source: "OnDemand Beziehung Form",
  events: {
    initialize: props<{
      planungsobjektLinearId: GUID | null | undefined;
    }>(),

    setLinearOnDemandBeziehungForm: props<{
      onDemandFormValue: PlanungsobjektWindowOnDemandFormData;
    }>(),
    saveLinearOnDemandBeziehung: emptyProps(),

    /**
     * Initialisierung des OnDemand-Beziehungs-Formulars
     */
    getInitialOnDemandBeziehungFormData: props<{
      planungsobjektLinearId: GUID | null | undefined;
    }>(),

    /**
     * Initialisierung des OnDemand-Beziehungs-Formulars erfolgreich
     */
    getInitialOnDemandBeziehungFormDataSuccess: props<{
      ondemandFormValue: PlanungsobjektWindowOnDemandFormData;
    }>(),

    updateOnDemandBeziehungFormValuesSuccess: props<{
      onDemandFormValue: PlanungsobjektWindowOnDemandFormData;
    }>(),
    /**
     * Erstellen für Vorgeschlagen/Vorgeplant
     */
    linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen: props<{
      command: LinearOnDemandBeziehungVorgeschlagenVorgeplantErstellenCommand;
    }>(),
    linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess: props<{
      planungsobjekte: PlanungsobjekteDto;
      // Die Id des Planungsobjekts, für das die Beziehung erstellt wurde. Wird benötigt, um die Beziehungen zu aktualisieren
      currentPlanungsobjektId: GUID;
    }>(),
    /**
     * Erstellen für Vorgeschlagen/Vorgeplant fehlgeschlagen. Kann passieren, wenn die Beziehung in CREATE usecases erstellt wird,
     * was nicht passieren sollte.
     */
    linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenFailure: emptyProps(),
    /**
     * Aktualisieren für Vorgeschlagen/Vorgeplant
     */
    linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren: props<{
      enabeledFormGroupValues: PlanungsobjektWindowOnDemandFormData;
    }>(),

    linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess: props<{
      planungsobjekte: PlanungsobjekteDto;
      // Die Id des betroffenen Planungsobjekts, für das die Beziehung aktualisiert wurde. Wird benötigt, um die Beziehungen zu aktualisieren
      currentPlanungsobjektId: GUID;
    }>(),

    /**
     * Aktualisieren für Vorgeschlagen/Vorgeplant fehlgeschlagen. Kann passieren, wenn die Beziehung in CREATE usecases erstellt wird,
     * was nicht passieren sollte.
     */
    linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenFailure: emptyProps(),

    /**
     * Erstellen für Vorgemerkt
     */
    linearOnDemandBeziehungVorgemerktErstellen: props<{
      command: LinearOnDemandBeziehungVorgemerktErstellenCommand;
    }>(),
    /**
     * Erstellen für Vorgemerkt erfolgreich
     */
    linearOnDemandBeziehungVorgemerktErstellenSuccess: props<{
      planungsobjekte: PlanungsobjekteDto;
      // Die Id des Planungsobjekts, für das die Beziehung erstellt wurde. Wird benötigt, um die Beziehungen zu aktualisieren
      currentPlanungsobjektId: GUID;
    }>(),
    /**
     * Erstellen für Vorgemerkt fehlgeschlagen. Kann passieren, wenn die Beziehung in CREATE usecases erstellt wird,
     * was nicht passieren sollte.
     */
    linearOnDemandBeziehungVorgemerktErstellenFailure: emptyProps(),
    /**
     * Aktualisieren für Vorgemerkt
     */
    linearOnDemandBeziehungVorgemerktAktualisieren: props<{
      command: LinearOnDemandBeziehungVorgemerktAktualisierenCommand;
    }>(),
    /**
     * Aktualisieren für Vorgemerkt erfolgreich
     */
    linearOnDemandBeziehungVorgemerktAktualisierenSuccess: props<{
      planungsobjekte: PlanungsobjekteDto;
      // Die Id des betroffenen Planungsobjekts, für das die Beziehung aktualisiert wurde. Wird benötigt, um die Beziehungen zu aktualisieren
      currentPlanungsobjektId: GUID;
    }>(),
    /**
     * Aktualisieren für Vorgeschlagen/Vorgeplant fehlgeschlagen. Kann passieren, wenn die Beziehung in CREATE usecases erstellt wird,
     * was nicht passieren sollte.
     */
    linearOnDemandBeziehungVorgemerktAktualisierenFailure: emptyProps(),

    /**
     * Setzt die Formularwerte auf die initalen Werte zurück. Wird generell getriggert, wenn das Formular geschlossen
     * und der Service zerstört wird.
     */
    resetOnDemandBeziehungFormValues: emptyProps(),
    /**
     * Feld-Update war nicht notwendig, da keine Änderungen vorgenommen wurden
     */
    noChangesNecessary: emptyProps(),
  },
});
