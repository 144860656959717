import { Component, ContentChild, Input } from "@angular/core";
import { AbstractControl, FormControlDirective, FormControlName } from "@angular/forms";
import { LabelDirective } from "@progress/kendo-angular-label";
import { FormControlState, FormControlValueTypes, NgrxFormControlDirective } from "ngrx-forms";
import { Icons } from "src/app/models/icons";

/**
 * Komponente, die über ihren Content ein Formularfeld rendert.
 * Wenn im Content eine FormControlName oder FormControlDirective vorhanden ist,
 * können automatisch die Validierungsfehler ausgelesen und über die
 * {@link FormFieldErrorComponent} angezeigt werden.
 */
@Component({
  selector: "app-form-field",
  templateUrl: "./form-field.component.html",
  styleUrls: ["./form-field.component.scss"],
})
export class FormFieldComponent {
  @Input() label?: string;
  @Input() hint?: string;
  /**
   * Ob ein Ladeindikator angezeigt werden soll.
   */
  @Input() loading = false;
  /**
   * Das Feld, für das das Label ist. Sollte im Idealfall eine Referenz auf ein Element
   * sein, das im Template via #variable definiert wurde.
   */
  @Input() for: LabelDirective["for"];

  @Input() formControlState: FormControlState<FormControlValueTypes> | null;
  @ContentChild(NgrxFormControlDirective) formControl?: NgrxFormControlDirective<unknown>;

  @Input() control?: AbstractControl;
  @ContentChild(FormControlName) angularFormControlName?: FormControlName;
  @ContentChild(FormControlDirective) angularFormControlDirective?: FormControlDirective;

  Icons = Icons;

  get controlState() {
    return this.formControlState ?? this.formControl?.state;
  }

  get showErrors(): boolean {
    return !!(
      this.controlState?.isInvalid &&
      (this.controlState?.isTouched || this.controlState?.isDirty)
    );
  }

  get angularControl() {
    return (
      this.control ??
      this.angularFormControlName?.control ??
      this.angularFormControlDirective?.control
    );
  }
}
