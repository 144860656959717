import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { BlockansichtDefinition } from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";

export enum PlanungsobjektWindowUseCase {
  CREATE_ONDEMAND = "CREATE_ONDEMAND",
  EDIT_ONDEMAND = "EDIT_ONDEMAND",
  READONLY_ONDEMAND = "READONLY_ONDEMAND",
  CREATE_LINEAR_SENDEPLATZ = "CREATE_LINEAR_SENDEPLATZ",
  EDIT_LINEAR_SENDEPLATZ = "EDIT_LINEAR_SENDEPLATZ",
  CREATE_LINEAR_BLOCKANSICHT = "CREATE_LINEAR_BLOCKANSICHT",
  EDIT_LINEAR_BLOCKANSICHT = "EDIT_LINEAR_BLOCKANSICHT",
  READONLY_LINEAR = "READONLY_LINEAR",
}

export enum CrossUpdateUseCase {
  PLANLAENGENHELPER = "PLANLAENGENHELPER",
  WANNBEZUG_ONDEMAND = "WAENNBEZUG_ONDEMAND",
  ONDEMAND_BEZIEHUNG = "ONDEMAND_BEZIEHUNG",
}
export const planungsobjektWindowSendeplatzUseCases = (<const>[
  PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ,
  PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ,
]) satisfies PlanungsobjektWindowUseCase[];
export type SendeplatzUseCases = (typeof planungsobjektWindowLinearUseCases)[number];

export const planungsobjektWindowLinearUseCases = (<const>[
  PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ,
  PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ,
  PlanungsobjektWindowUseCase.READONLY_LINEAR,
  PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT,
  PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT,
]) satisfies PlanungsobjektWindowUseCase[];
export type LinearUseCase = (typeof planungsobjektWindowLinearUseCases)[number];

export const planungsobjektWindowOnDemandUseCases = (<const>[
  PlanungsobjektWindowUseCase.CREATE_ONDEMAND,
  PlanungsobjektWindowUseCase.EDIT_ONDEMAND,
  PlanungsobjektWindowUseCase.READONLY_ONDEMAND,
]) satisfies PlanungsobjektWindowUseCase[];
export type OnDemandUseCase = (typeof planungsobjektWindowOnDemandUseCases)[number];

export const planungsobjektWindowEditUseCases = (<const>[
  PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ,
  PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT,
  PlanungsobjektWindowUseCase.EDIT_ONDEMAND,
]) satisfies PlanungsobjektWindowUseCase[];
export type EditUseCase = (typeof planungsobjektWindowEditUseCases)[number];

export const planungsobjektWindowReadUseCases = (<const>[
  PlanungsobjektWindowUseCase.READONLY_LINEAR,
  PlanungsobjektWindowUseCase.READONLY_ONDEMAND,
]) satisfies PlanungsobjektWindowUseCase[];
export type ReadonlyUseCase = (typeof planungsobjektWindowReadUseCases)[number];

export const planungsobjektWindowCreateUseCases = (<const>[
  PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ,
  PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT,
  PlanungsobjektWindowUseCase.CREATE_ONDEMAND,
]) satisfies PlanungsobjektWindowUseCase[];
export type CreateUseCase = (typeof planungsobjektWindowCreateUseCases)[number];

export type Publikationsart = "ondemand" | "linear";

export type WannPresets = {
  sendetag: string;
  beginnzeit: string;
};

export type WannSendeplatzPresets = {
  /**
   * Eigentlich die Schemaplatz-Länge, aber "planlaenge" lässt sich besser in die FormControls patchen
   */
  planlaenge: number;
} & WannPresets;

export type WannBlockansichtPresets = {
  variante: number;
} & WannPresets;

export type CreatePlanungsobjektOnDemandInput = {
  usecase: PlanungsobjektWindowUseCase.CREATE_ONDEMAND;
  planungsobjektId: null;
  kanal: Kanal;
  planungskontext: Planungskontext;
};

export type EditPlanungsobjektOnDemandInput = {
  usecase: PlanungsobjektWindowUseCase.EDIT_ONDEMAND;
  planungsobjektId: string;
  planungskontext: Planungskontext;
};

export type CreatePlanungsobjektLinearInput = {
  usecase: PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ;
  planungsobjektId: null;
  kanal: Kanal;
  wannPreset: WannSendeplatzPresets;
  planungskontext: Planungskontext;
};

export type EditPlanungsobjektLinearInput = {
  usecase: PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ;
  planungsobjektId: string;
  planungskontext: Planungskontext;
};

export type CreatePlanungsobjektLinearBlockansichtInput = {
  usecase: PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT;
  planungsobjektId: null;
  kanal: Kanal;
  /**
   * Blockansichtdefinition für die aktuelle Ansicht mit aktuellem Jahr
   */
  blockansichtDefinition: BlockansichtDefinition;
  /**
   * Alle Blockansichtdefinitionen für die aktuellen Ansicht aber alle Jahre
   */
  blockansichtDefinitionen: BlockansichtDefinition[];
  wannPreset: WannBlockansichtPresets;
  planungskontext: Planungskontext;
};

export type EditPlanungsobjektLinearBlockansichtInput = {
  usecase: PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT;
  planungsobjektId: string;
  /**
   * Blockansichtdefinition für die aktuelle Ansicht mit aktuellem Jahr
   */
  blockansichtDefinition: BlockansichtDefinition;
  /**
   * Alle Blockansichtdefinitionen für die aktuellen Ansicht aber alle Jahre
   */
  blockansichtDefinitionen: BlockansichtDefinition[];
  planungskontext: Planungskontext;
};

export type ReadonlyLinearInput = {
  usecase: PlanungsobjektWindowUseCase.READONLY_LINEAR;
  planungsobjektId: string;
  planungskontext: Planungskontext;
};
export type ReadonlyOnDemandInput = {
  usecase: PlanungsobjektWindowUseCase.READONLY_ONDEMAND;
  planungsobjektId: string;
  planungskontext: Planungskontext;
};

export type PlanungsobjektBlockansichtInput =
  | CreatePlanungsobjektLinearBlockansichtInput
  | EditPlanungsobjektLinearBlockansichtInput;

export type PlanungsobjektCreateInput =
  | CreatePlanungsobjektLinearBlockansichtInput
  | CreatePlanungsobjektLinearInput
  | CreatePlanungsobjektOnDemandInput;

export type PlanungsobjektEditInput =
  | EditPlanungsobjektLinearBlockansichtInput
  | EditPlanungsobjektLinearInput
  | EditPlanungsobjektOnDemandInput;

export type PlanungsobjektReadonlyInput = ReadonlyLinearInput | ReadonlyOnDemandInput;

export type PlanungsobjektWindowInput =
  | PlanungsobjektCreateInput
  | PlanungsobjektReadonlyInput
  | PlanungsobjektEditInput;


export type PlanungsobjektSendeplatzInput = PlanungsobjektWindowInput & { usecase: SendeplatzUseCases };

export type PlanungsobjektLinearInput = PlanungsobjektWindowInput & { usecase: LinearUseCase };

export type PlanungsobjektOnDemandInput = PlanungsobjektWindowInput & { usecase: OnDemandUseCase };

export type PlanungsobjektWindowInputWithPlanungsobjekt = PlanungsobjektWindowInput &
  (
    | {
        usecase: Exclude<PlanungsobjektWindowUseCase, UseCasesWithPlanungsobjekt>;
        planungsobjekt?: undefined;
      }
    | {
        usecase: LinearUseCase;
        planungsobjekt: PlanungsobjektLinearDto;
      }
    | {
        usecase: OnDemandUseCase;
        planungsobjekt: PlanungsobjektOnDemandDto;
      }
  );

export const useCasesWithPlanungsobjekt = [
  ...planungsobjektWindowEditUseCases,
  ...planungsobjektWindowReadUseCases,
] as const;

export type UseCasesWithPlanungsobjekt = (typeof useCasesWithPlanungsobjekt)[number];
