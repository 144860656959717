<div
  *ngIf="validationErrorMessages"
  aria-label="Validierungsfehler"
  role="tooltip"
  [title]="validationErrorMessages"
>
  <kendo-svg-icon
    size="small"
    themeColor="error"
    [icon]="Icons.warningCircle"
  ></kendo-svg-icon>
</div>
