import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { GUID } from "src/app/models";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { BlockansichtDefinition } from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";
import {
  PlanungsobjektAcceptWindowInput,
  PlanungsobjektAcceptWindowResult,
} from "src/app/shared/windows/planungsobjekt-accept-window/planungsobjekt-accept-window.component";
import {
  PlanungsobjektEditInput,
  PlanungsobjektWindowInputWithPlanungsobjekt,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { PlanungsobjektWindowTabEnum } from "./planungsobjekt-window.model";

export const planungsobjektWindowActions = createActionGroup({
  source: "Planungsobjekt Window",
  events: {
    openDeletePlanungsobjektOnSendeplatzWindow: props<{
      planungsobjektId: GUID;
      titel: string;
    }>(),
    openDeletePlanungsobjektWindow: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
    openDeletePlanungsobjektOnBlockansichtDialog: props<{
      planungsobjektId: GUID;
      titel: string;
    }>(),
    openUpdateOnDemandOnVODWindow: props<{
      linearId: string | null;
      onDemandId: string;
    }>(),
    openLinearOnDemandAcceptWindow: props<{ windowInput: PlanungsobjektAcceptWindowInput }>(),
    openPlanungsobjektWindow: props<{ input: PlanungsobjektWindowInputWithPlanungsobjekt }>(),
    openPlanungsobjektWindowReadonlyForId: props<{
      planungsobjektId: string;
      planungsobjektType: "ondemand" | "linear";
    }>(),
    openPlanungsobjektLinearWithPlanungOnSendeplatzWindow: props<{
      linearId: string;
    }>(),
    openPlanungsobjektLinearWithPlanungOnBlockansichtWindow: props<{
      linearId: string;
      blockansichtDefinition: BlockansichtDefinition;
      blockansichtDefinitionen: BlockansichtDefinition[];
    }>(),

    transitionToEditWindow: props<{ input: PlanungsobjektEditInput }>(),

    /**
     * Stößt das Schließen des Planungsobjekt Windows an.
     */
    closePlanungsobjektWindow: emptyProps(),
    tryClosingWithoutSaving: emptyProps(),

    trySavingAndClosing: emptyProps(),
    /**
     * Wird aufgerufen, wenn das Planungsobjekt Window geschlossen wurde.
     */
    planungsobjektWindowClosed: emptyProps(),
    deletePlanungsobjekt: emptyProps(),
    /**
     * Dient als Anfrage, den Tab zu wechseln. Wenn das Formular noch Änderungen enthält, wird
     * der Dialog zum Zwischenspeichern geöffnet es sei denn das Formular ist nicht valide.
     * Dann wird der Tab nicht gewechselt und eine Fehlermeldung angezeigt.
     */
    tryChangeTab: props<{ newTab: PlanungsobjektWindowTabEnum; isFormValid: boolean }>(),
    changePlanungsobjektWindowTab: props<{ newTab: PlanungsobjektWindowTabEnum }>(),

    openConfirmPlanungsobjektSaveDialog: props<{ newTab: PlanungsobjektWindowTabEnum }>(),
    confirmPlanungsobjektSaveDialog: props<{ newTab: PlanungsobjektWindowTabEnum }>(),

    openConfirmGetitVerknuepfungAufhebenDialog: props<{ planungsobjekt: PlanungsobjektDto }>(),
    confirmGetitVerknuepfungAufhebenDialog: props<{
      planungsobjektId: GUID;
      produktEingenschaftenBeibehalten: boolean;
    }>(),
    rejectGetitVerknuepfungAufhebenDialog: emptyProps(),

    handleLinearOnDemandAcceptWindowResult: props<{
      result: PlanungsobjektAcceptWindowResult | WindowCloseResult;
    }>(),
    cancelChangeTab: emptyProps(),
    changeTabWithoutSideeffects: emptyProps(),
    closeWindow: emptyProps(),
  },
});
