import { Component, Input } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { Icons } from "src/app/models/icons";
import { entriesOf } from "src/app/utils/object-utils";
import { getValidationErrorMessage } from "./form-field-error.utils";

/**
 * Komponente, die Validierungsfehler für ein Formularfeld anzeigt.
 */
@Component({
  selector: "app-form-field-error",
  templateUrl: "./form-field-error.component.html",
  styleUrls: ["./form-field-error.component.scss"],
})
export class FormFieldErrorComponent {
  @Input({ required: true }) validationErrors: ValidationErrors;

  get validationErrorMessages() {
    return !this.validationErrors || Object.keys(this.validationErrors).length === 0
      ? null
      : entriesOf(this.validationErrors)
          .map(([key, value]) => getValidationErrorMessage(key, value))
          .join("\n");
  }

  readonly Icons = Icons;
}
