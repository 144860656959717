import { Wochentag } from "src/app/models/openapi/model/wochentag";

/**
 * Validierungsnachrichten für die verschiedenen Validatoren.
 */
const validationErrorMessages: Record<string, string | ((value: any) => string)> = {
  required: "Wird benötigt.",
  distinctRecordValues: "Werte müssen unterschiedlich sein.",
  distinctFrom: "Muss sich von dem anderen Wert unterscheiden.",
  minLength: (error: { minLength: number; value: string; actualLength: number }) =>
    `Muss aus mindestens ${error.minLength} Zeichen bestehen.`,
  minLengthTrimmed: (error: { minLengthTrimmed: number }) =>
    `Muss aus mindestens ${error.minLengthTrimmed} Zeichen bestehen.`,
  maxLength: (maxLength: number) => `Darf maximal aus ${maxLength} Zeichen bestehen.`,
  pattern: (patternMessage: string) => patternMessage,
  maxExceeded: (maxExceededMessage: string) => maxExceededMessage,
  valueBefore: (valueBefore: { otherLabel: string }) =>
    `Darf nicht nach "${valueBefore.otherLabel}" liegen.`,
  dateBefore: (dateBefore: { otherLabel: string }) =>
    `Datum muss vor dem Datum in "${dateBefore.otherLabel}" liegen.`,
  dateAfter: (dateAfter: { otherLabel: string }) =>
    `Datum muss nach dem Datum in "${dateAfter.otherLabel}" liegen.`,
  valueAfter: (valueAfter: { otherLabel: string }) =>
    `Darf nicht vor "${valueAfter.otherLabel}" liegen`,
  valueBeforeWithReferenceKey: (valueBefore: { otherLabel: string }) =>
    `Darf nicht nach "${valueBefore.otherLabel}" liegen.`,
  /**
   * Validator von Kendo Date/Timepicker, wenn das Datum nicht vollständig ist.
   */
  incompleteDate: "Unvollständiges Datum.",
  minDate: (minDate: Date) => `Muss nach dem ${minDate.toLocaleDateString()} liegen.`,
  wochentage: (wochentag: Wochentag[]) => `Muss ein ${wochentag[0]} oder ${wochentag[1]} sein.`,
  minError: (min: number) => `Muss mindestens ${min} sein.`,
  greaterThanOrEqualTo: (error: { comparand: number }) =>
    `Muss größer oder gleich ${error.comparand} sein.`,
  greaterThan: (error: { comparand: number }) => `Muss größer als ${error.comparand} sein.`,
  lessThanOrEqualTo: (error: { comparand: number }) =>
    `Muss kleiner oder gleich ${error.comparand} sein.`,
  lessThan: (error: { comparand: number }) => `Muss kleiner als ${error.comparand} sein.`,
  wochentag: (error: { wochentag: Wochentag }) => "Sendetag liegt nicht im Anzeigebereich",
  numberBetween: (error: { min: number; max: number; actual: number }) =>
    `${error.actual} muss zwischen ${error.min} und ${error.max} liegen.`,
  notEqualTo: (error: { comparand: string }) => `Muss sich von "${error.comparand}" unterscheiden.`,
};

/**
 * Erzeugt für einen gegebenen Key und Wert die passende Validierungsnachricht.
 */
export const getValidationErrorMessage = (key: string, value: any): string => {
  const message = validationErrorMessages[key];
  if (!message) {
    throw new Error(`Für Validierungsfehler "${key}" ist keine Nachricht definiert.`);
  }
  return typeof message === "function" ? message(value) : message;
};
