import { format } from "date-fns";
import { Boxed, FormGroupState, box, createFormGroupState } from "ngrx-forms";
import { OnDemandFilter } from "src/app/models/openapi/model/on-demand-filter";
import { firstDayOfCurrentYear, lastDayOfCurrentYear } from "src/app/utils/date-utils";
import { ContentCommunity, Genre, Planungskontext, Redaktion } from "tests/common/generated/api";
import {
  OnDemandGroupingCriteria,
  OnDemandOrderingProperty,
} from "./on-demand-table/on-demand-table.model";
export const onDemandPageTabs = ["benachrichtigungen", "merkliste", "filter", "weiteres"] as const;
export type OnDemandPageTab = (typeof onDemandPageTabs)[number];

export type OnDemandTablePageQueryParams = {
  von: string;
  bis: string;
  redaktionen: string[];
  genres: string[];
  planungskontexte: string[];
  contentCommunities: string[];
  group: string[];
  order: string;
  logicOperator: string;
};

export interface YearDropdownItem {
  text: string;
  value: {
    von: string;
    bis: string;
  } | null;
  tooltip?: string;
}

export const isOnDemandPageTab = (value: string): value is OnDemandPageTab =>
  onDemandPageTabs.includes(value as OnDemandPageTab);

export type OnDemandSearchFormData = {
  orderByProperty: OnDemandOrderingProperty;
  groupByPropertyOne: OnDemandGroupingCriteria;
  groupByPropertyTwo: OnDemandGroupingCriteria | null;
  useOperatorUND: boolean;
  filterRedaktionen: Boxed<Redaktion[]>;
  filterGenres: Boxed<Genre[]>;
  filterPlanungskontexte: Boxed<Planungskontext[]>;
  filterContentCommunities: Boxed<ContentCommunity[]>;
  timerangeOnlineAb: string;
  timerangeOnlineBis: string;
};
export type OnDemandSearchFormState = FormGroupState<OnDemandSearchFormData>;
export const onDemandSearchFormId = "OnDemandSearchForm";
export const emtpyOnDemandSearchForm: OnDemandSearchFormData = {
  orderByProperty: "onlineAb",
  groupByPropertyOne: "redaktion",
  groupByPropertyTwo: "genre",
  useOperatorUND: false,
  filterRedaktionen: box([]),
  filterGenres: box([]),
  filterPlanungskontexte: box([]),
  filterContentCommunities: box([]),
  timerangeOnlineAb: format(firstDayOfCurrentYear(), "yyyy-MM-dd"),
  timerangeOnlineBis: format(lastDayOfCurrentYear(), "yyyy-MM-dd"),
};
export const initalOnDemandSearchFormState: OnDemandSearchFormState = createFormGroupState(
  onDemandSearchFormId,
  emtpyOnDemandSearchForm,
);

export interface OnDemandPageState {
  sidebarExpanded: boolean;
  activeSidebarTab: OnDemandPageTab;
  searchNeeded: boolean;
  quickFilter: OnDemandFilter | undefined;
  searchForm: OnDemandSearchFormState;
}

export const initialState: OnDemandPageState = {
  sidebarExpanded: false,
  activeSidebarTab: "filter",
  searchNeeded: true,
  quickFilter: undefined,
  searchForm: initalOnDemandSearchFormState,
};
