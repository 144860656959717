import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { greaterThanOrEqualTo, required } from "ngrx-forms/validation";
import * as CustomValidators from "src/app/shared/form-inputs/custom-validators-ngrx";
import {
  ValidationMap,
  createReducerAndCustomFormActions,
  createValidationRules,
} from "src/app/utils/ngrx-forms";
import {
  SerienWindowFeatureState,
  SerienWindowFormData,
  initialSerienWindowFeatureState,
  serienWindowFormId,
} from "./serien-window.model";

const serienWindowFormValidationRules = (
  form: SerienWindowFormData,
): ValidationMap<SerienWindowFormData> => {
  return {
    folgennummerAb: [
      required,
      greaterThanOrEqualTo(1),
      CustomValidators.valueBefore(form, "folgennummerBis", "Folgennummer bis"),
    ],
    folgennummerBis: [
      required,
      greaterThanOrEqualTo(1),
      CustomValidators.valueAfter(form, "folgennummerAb", "Folgennummer ab"),
    ],
    staffelnummer: [greaterThanOrEqualTo(1)],
    anzahlFolgen: [
      greaterThanOrEqualTo(1),
      CustomValidators.valueAfter(form, "folgennummerBis", "Folgennummer bis"),
    ],
  };
};

const { actions, reducer, selectors } = createReducerAndCustomFormActions<
  SerienWindowFeatureState,
  "formState"
>(initialSerienWindowFeatureState, serienWindowFormId, "formState");

export const serienWindowFormSelectors = selectors;
export const serienWindowFormActions = actions;
export const serienWindowFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (s) => s.formState,
  createValidationRules(serienWindowFormValidationRules),
);
