<ng-container>
  <label class="like-floating-label" [for]="for">
    <div class="k-d-flex k-align-items-center k-justify-content-between">
      <span
        class="label-text"
        [ngClass]="{
          'u-text-error': shouldShowError,
        }"
        >{{ text }}{{ isRequired ? " *" : "" }}
        <span *ngIf="hint">
          <kendo-svg-icon
            size="small"
            [icon]="Icons.information"
            [title]="hint"
          ></kendo-svg-icon> </span
      ></span>
      <span
        *ngIf="loading"
        class="k-i-loading u-text-primary-contrast"
        data-testid="loading-indicator"
      ></span>
      <ng-content select="[showAfterLabel]"></ng-content>
    </div>
    <!-- zeige den Inhalt an, der von außen mitgegeben wird -->
    <ng-content></ng-content>
  </label>
</ng-container>
