import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { OnChangeFn, OnTouchedFn, setEnablementForControl } from "src/app/utils/form-utils";
import { noop } from "src/app/utils/function-utils";
import { DurationPipe } from "../../pipes/duration.pipe";

@Component({
  selector: "app-masked-input",
  templateUrl: "./masked-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MaskedInputComponent),
    },
  ],
})
export class MaskedInputComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() dataTestId: string;
  @Input() isRequired: boolean;
  @Input() readOnly: boolean;

  private readonly destroy$ = new Subject<void>();

  public mask = "000:S0";
  public rules: { [key: string]: RegExp } = {
    // nur Zahlen von 0-5 sind erlaubt
    S: /[0-5]/,
  };

  changeValue: string | null = null;
  viewValue: string | null = null;
  changed: OnChangeFn<number | null> = noop;
  touched: OnTouchedFn = noop;

  form = new FormControl("");

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly) {
      setEnablementForControl(this.form, !this.readOnly);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //#region ControlValueAccessor interface methods
  writeValue(value: number | null): void {
    const errors: ValidationErrors = {};
    const viewValue = DurationPipe.transform(value, 3);
    const minutes = DurationPipe.getMinutes(viewValue);
    if (minutes.toString().length > 3) {
      errors.maxExceeded = "Die Länge überschreitet den Maximalwert von 999:99";
    }

    if (Object.keys(errors).length > 0) {
      this.form.setErrors(errors);
      this.cdr.detectChanges();
    } else {
      this.form.setValue(viewValue, { emitEvent: false });
    }
    this.viewValue = viewValue;
    this.changeValue = viewValue;
    this.setDisabledState(this.readOnly);
  }

  registerOnChange(fn: OnChangeFn<number | null>): void {
    this.changed = fn;
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      // Speichere den letzten Wert, damit er später bei der onBlur Methode verwendet werden kann
      this.changeValue = value;
      // Schreibt den Wert über den Callback nur an die Parent FormGroup zurück, wenn sich der Wert tatsächlich
      // verändert hat. Dies aktualisiert das FormControl Endzeit, welches von der Parent Komponente verwendet wird.
      if (DurationPipe.transformBack(value) !== DurationPipe.transformBack(this.viewValue)) {
        this.changed(DurationPipe.transformBack(value) ?? null);
      }
    });
  }

  registerOnTouched(fn: () => void): void {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean): void {
    disabled ? this.form.disable() : this.form.enable();
  }
  //#endregion ControlValueAccessor interface methods

  onBlur() {
    // FormControl wurde eventuell "angefasst" aber nicht verändert.
    if (this.viewValue === this.changeValue) return;

    const noLastValue = DurationPipe.transformBack(this.changeValue) === 0;
    if (noLastValue) {
      if (this.isRequired) {
        // Falls isRequired true ist wird eine "manuelle" Validierung durchgeführt, indem der Error required gesetzt
        // wird. Das Setzen über die Reactive Forms funktionierte nicht. Weder in der Parent Komponente noch in dieser
        // Komponente, über das FormControl.
        this.form.setErrors(Validators.required);
        this.cdr.detectChanges();
      } else {
        // Wird ausgeführt, wenn der Wert des FormControls leer oder die Länge 0 ist.
        this.form.reset();
      }
      return;
    }

    // Ersetzt alle Leerzeichen durch Nullen, sodass die Länge immer das Format 000:00 einhält
    const paddedViewValue = this.changeValue?.replace(/\s/g, "0") ?? "";
    if (paddedViewValue !== this.changeValue) {
      this.viewValue = paddedViewValue;
      this.form.setValue(paddedViewValue, { emitEvent: false });
    }
  }
}
