<kendo-multiselect
  #multiselect
  textField="text"
  valueField="value"
  [autoClose]="autoClose"
  [clearButton]="clearButton"
  [data]="options"
  [fillMode]="fillMode"
  [formControl]="formControl"
  [kendoMultiSelectSummaryTag]="summaryTagMaxItems"
  [listHeight]="dropdownListHeight"
  [valuePrimitive]="true"
>
  <!-- [(ngModel)]="_value" -->
  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
    {{ dataItems.length }} Filter ausgewählt
  </ng-template>
  <ng-template kendoMultiSelectHeaderTemplate>
    <span class="select-option header" (click)="onClickSelectAll()">
      <input
        kendoCheckBox
        type="checkbox"
        [checked]="isCheckedSelectAll"
        [indeterminate]="isPartiallyChecked"
      />
      <kendo-label>{{ toggleAllText }}</kendo-label>
    </span>
  </ng-template>

  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <span class="select-option">
      <kendo-label [for]="'chk-' + dataItem.value">
        <input
          kendoCheckBox
          type="checkbox"
          [checked]="isItemSelected(dataItem.value)"
          [id]="'chk-' + dataItem.value"
        />
      </kendo-label>
    </span>
    <span>{{ dataItem.text }}</span>
  </ng-template>

  <kendo-multiselect-messages
    [noDataText]="'Keine ' + label + ' vorhanden'"
  ></kendo-multiselect-messages>
</kendo-multiselect>
