import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { SideEffectNotificationDto } from "src/app/models/openapi/model/side-effect-notification-dto";

export const notificationActions = createActionGroup({
  source: "Notification",
  events: {
    showNotification: props<{ message: string; notificationType: NotificationStyle }>(),
    showNotifications: props<{ sideEffectNotificationDto: SideEffectNotificationDto[] }>(),
    noNotificationNecessary: emptyProps(),
  },
});
