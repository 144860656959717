import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgrxFormsModule } from "ngrx-forms";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, NgrxFormsModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
})
export class AngularModule {}
