import { Injectable } from "@angular/core";
import {
  addDays,
  addMinutes,
  addSeconds,
  areIntervalsOverlapping,
  eachDayOfInterval,
  setHours,
  setMinutes,
} from "date-fns";
import { BlockansichtPillPositioningService } from "src/app/pages/ansichten/blockansicht/blockansicht-chip-positioning.service";
import {
  BlockansichtDataVM,
  BlockansichtDefinition,
  ManuelleVariantenzeile,
} from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { KanalOffsetUtils, wochentagToDayOfWeek } from "src/app/utils/kanal-offset-utils";
import { isDefined } from "src/app/utils/object-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { ZOOM_LEVELS } from "../enums/zoom-level";
import { DeveloperError } from "../errors/technical.error";
import { MovePlanungsobjektLinearToVorgeplantBlockCommand } from "../openapi/model/move-planungsobjekt-linear-to-vorgeplant-block-command";
import { PlanungsobjektDto } from "../openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "../openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektLinearVorgeplantBlockAktualisierenCommand } from "../openapi/model/planungsobjekt-linear-vorgeplant-block-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantBlockErstellenCommand } from "../openapi/model/planungsobjekt-linear-vorgeplant-block-erstellen-command";
import { ZoomLevel } from "../openapi/model/zoom-level";
import { AnsichtViewModel, MultiAnsichtViewModel } from "../viewmodels/ansicht-viewmodel";

@Injectable({
  providedIn: "root",
})
export class BlockansichtMapper {
  mapPlanungsobjektToCreatePlanungsobjektLinearVorgeplantBlockCommand(
    planungsobjekt: PlanungsobjektDto,
  ): PlanungsobjektLinearVorgeplantBlockErstellenCommand {
    const {
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      planlaenge,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
      produkttitel,
      produkttitelMultipart,
    } = planungsobjekt;

    const {
      sendetag,
      beginnzeit,
      // TODO
      // kanal ist kein Property der Publikationsplanung und daher im folgenden 'undefined'
      // kanal,
      variante,
    } = planungsobjekt.publikationsplanung ?? {};

    if (!sendetag || !beginnzeit || variante == null || planlaenge == null) {
      throw new Error("PlanungsobjektDto is missing required properties");
    }

    const kanal = planungsobjekt.kanal;

    const command: PlanungsobjektLinearVorgeplantBlockErstellenCommand = {
      kanal,
      sendetag,
      beginnzeit,
      laenge: planlaenge,
      variante,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    };

    return command;
  }

  mapPlanungsobjektToUpdatePlanungsobjektLinearVorgeplantBlockCommand(
    planungsobjekt: PlanungsobjektDto,
  ): PlanungsobjektLinearVorgeplantBlockAktualisierenCommand {
    const {
      id,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      planlaenge,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    } = planungsobjekt;

    const { sendetag, beginnzeit, variante } = planungsobjekt.publikationsplanung ?? {};
    const kanal = planungsobjekt.kanal;

    if (!sendetag || !beginnzeit || variante == null || planlaenge == null) {
      throw new Error("PlanungsobjektDto is missing required properties");
    }

    const command: PlanungsobjektLinearVorgeplantBlockAktualisierenCommand = {
      id,
      kanal,
      sendetag,
      beginnzeit,
      laenge: planlaenge,
      variante,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      titel,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    };

    return command;
  }

  mapPlanungsobjektToMovePlanungsobjektLinearToVorgeplantBlockCommand(
    planungsobjekt: PlanungsobjektDto,
  ): MovePlanungsobjektLinearToVorgeplantBlockCommand {
    const { id, planlaenge } = planungsobjekt;

    const { kanal, sendetag, beginnzeit, variante } = planungsobjekt.publikationsplanung ?? {};

    if (!sendetag || !beginnzeit || variante == null || planlaenge == null) {
      throw new Error("PlanungsobjektDto is missing required properties");
    }

    // TODO: Achtung hier muss der Code entsprechend im Front und/oder Backend angepasst werden damit entweder der
    // Sendetag im PublikationKey oder eine neue Umrechnung für den Kalendertag im Backend verwendet wird.
    const command: MovePlanungsobjektLinearToVorgeplantBlockCommand = {
      kanal: planungsobjekt.kanal,
      sendetag,
      beginnzeit,
      variante,
      laenge: planlaenge,
      id,
    };
    return command;
  }

  static mapMultiAnsichtViewModelToBlockansichtDefinition(
    zoomLevel: ZoomLevel,
    multiAnsichtViewModel: MultiAnsichtViewModel,
  ) {
    return multiAnsichtViewModel.ansichtViewModels
      .filter((apvm) => apvm.primary === true || apvm.visible === true)
      .map((avm) =>
        this.mapAnsichtViewModelToBlockansichtDefinition(zoomLevel, avm.ansichtViewModel),
      )
      .sort((a, b) => a.ansichtViewModel.year - b.ansichtViewModel.year);
  }

  static mapAnsichtViewModelToBlockansichtDefinition(
    zoomLevel: ZoomLevel,
    ansichtViewModel: AnsichtViewModel,
  ): BlockansichtDefinition {
    if (ansichtViewModel.ansichtsdefinition.schemaplaetze.length !== 1) {
      throw Error("Blockansicht erwartet genau einen Schemaplatz in der Ansichtsdefinition");
    }

    //Es gibt nur einen Schemaplatz auf Blockansichten
    const schemaplatz = ansichtViewModel.ansichtsdefinition.schemaplaetze[0];

    // Der Wochentag im Schemaplatz entspricht dem kalendarischen Wochentag
    const { beginnzeit, laenge, wochentag } = schemaplatz;
    const laengeInMinuten = DateFnsService.secondsToMinutes(laenge);

    const start = DateFnsService.parseDateAndTimeToDateObject(
      beginnzeit,
      ansichtViewModel.zeitraum.start,
    );
    const end = DateFnsService.addMinutes(
      DateFnsService.parseDateAndTimeToDateObject(beginnzeit, ansichtViewModel.zeitraum.start),
      laengeInMinuten,
    );

    const blockWidth = BlockansichtMapper.computeBlockWidth(laengeInMinuten);
    const minutesPerColumn = ZOOM_LEVELS[zoomLevel].minutesPerBlock;

    const wochentagKalendertag = wochentag;
    const wochentagSendetag = KanalOffsetUtils.getSendewochentag(
      wochentagKalendertag,
      beginnzeit,
      ansichtViewModel.kanal,
    );

    return {
      timeRange: {
        start,
        end,
      },
      ansichtViewModel,
      beginnzeit,
      laengeInMinuten,
      wochentagKalendertag,
      wochentagSendetag,
      blockWidth,
      minutesPerColumn,
    };
  }

  static computeBlockWidth(laengeInMinuten: number) {
    const zweiStunden = 120;
    const blockMinimumWidth = 50;
    const newWidth = window.innerWidth / ((laengeInMinuten ?? 0) > zweiStunden ? 60 : 32);
    return Math.max(newWidth, blockMinimumWidth);
  }

  static mapBlockansichtDefinitionToBlockansichtViewmodel(
    blockansichtDefinition: BlockansichtDefinition[],
    planungsobjekte: PlanungsobjektLinearDto[],
    manuelleVariantenzeilen: Record<string, ManuelleVariantenzeile[]>,
  ): BlockansichtDataVM {
    if (blockansichtDefinition.length === 0) {
      throw new DeveloperError("BlockansichtDefinition ist leer oder nicht definiert.");
    }

    let rows: BlockansichtDataVM = {};
    blockansichtDefinition.forEach((blockansicht) => {
      rows = BlockansichtMapper.initializeRows(blockansicht, rows);
    });

    // Die Eigenschaften sind über alle BlockansichtDefinitionen gleichen
    const {
      beginnzeit: blockansichtBeginnzeit,
      laengeInMinuten: blockansichtLaengeInMinuten,
      blockWidth,
      minutesPerColumn,
    } = blockansichtDefinition[0];

    // gruppiere Planungsobjekte nach Sendetag
    const planungsobjekteGroupedBySendetag = planungsobjekte.reduce(
      (sendetage, planungsobjekt) => {
        const sendetag = planungsobjekt.sendetag;
        if (!sendetag) {
          throw new Error("Planungsobjekt auf Blockansicht benötigt einen gesetzten Sendetag.");
        }
        // Wird benötigt um die Planungsobjekte in die korrekten Zeilen zu platzieren
        const planungsobjektTimeRange =
          PlanungsobjektUtils.getTimeRangeIntervalForPlanungsobjekt(planungsobjekt);
        // Liegt das Intervall des Planungsobjekts in einem der BlockansichtIntervalle?
        // Wenn ja, dann brauchen wir den Sendetag des Blockansichtsintervalls
        const overlappingInterval = Object.values(rows).find((rowData) =>
          areIntervalsOverlapping(
            { start: rowData.start, end: rowData.end },
            planungsobjektTimeRange,
          ),
        );
        // Nicht Teil der Blockansicht
        if (!overlappingInterval) return sendetage;
        sendetage[overlappingInterval.sendetag] ??= [];
        sendetage[overlappingInterval.sendetag].push(planungsobjekt);
        return sendetage;
      },
      {} as Record<string, PlanungsobjektLinearDto[]>,
    );

    // Nur wenn es Planungsobjekte für den Sendetag gibt
    for (const [sendetag, planungsobjekteForSendetag] of Object.entries(
      planungsobjekteGroupedBySendetag,
    )) {
      const rowData = rows[sendetag];

      if (rowData) {
        // initialisieren der Variantenzeilen, um "Lücken" zu vermeiden
        let maxVariant = Math.max(
          ...planungsobjekteForSendetag
            .map((planungsobjekt) => planungsobjekt.variante)
            .filter(isDefined),
        );

        // Wenn es manuelle Variantenzeilen gibt, dann müssen diese mit berücksichtigt werden
        if (sendetag in manuelleVariantenzeilen) {
          maxVariant = Math.max(
            ...manuelleVariantenzeilen[sendetag].map((varianteZeile) => varianteZeile.variante),
            maxVariant,
          );
        }

        for (let variante = 0; variante <= maxVariant; variante++) {
          rowData.varianten[variante] = { variante, pillen: [] };
        }

        for (const planungsobjekt of planungsobjekteForSendetag) {
          const { variante } = planungsobjekt;
          if (variante === null || variante === undefined) {
            throw new Error("Planungsobjekt auf Blockansicht benötigt einen gesetzte Variante.");
          }

          const isInFirstRow = variante === 0;
          rowData.varianten[variante].pillen.push(
            BlockansichtPillPositioningService.calculatePillDataViewModel(
              planungsobjekt,
              rowData,
              isInFirstRow,
              blockansichtBeginnzeit,
              blockansichtLaengeInMinuten,
              blockWidth,
              minutesPerColumn,
            ),
          );
        }
      } else {
        console.log("rowData is null, but why?");
      }
    }

    // Nur wenn es manuelle Variantenzeilen auf Sendeplätzen ohne Planungsobjekte gibt
    const planungsobjektSendetage = Object.keys(planungsobjekteGroupedBySendetag);
    const variantenzeilenSendetageForEmptySendeplaetze = Object.keys(
      manuelleVariantenzeilen,
    ).filter((key) => !planungsobjektSendetage.includes(key));

    for (const sendetag of variantenzeilenSendetageForEmptySendeplaetze) {
      const rowData = rows[sendetag];

      const maxVariant = Math.max(
        ...manuelleVariantenzeilen[sendetag].map((varianteZeile) => varianteZeile.variante),
      );

      for (let variante = 0; variante <= maxVariant; variante++) {
        rowData.varianten[variante] = { variante, pillen: [] };
      }
    }

    return rows;
  }

  static initializeRows(
    { beginnzeit, laengeInMinuten, ansichtViewModel, wochentagKalendertag }: BlockansichtDefinition,
    rows: BlockansichtDataVM,
  ): BlockansichtDataVM {
    const [beginnzeitStunden, beginnzeitMinuten] = beginnzeit.split(":").map(Number);

    // bis zum ersten Wochentag der Ansicht springen
    let kalendertag = ansichtViewModel.zeitraum.start;
    const wochentagKalendertagDay = wochentagToDayOfWeek[wochentagKalendertag];
    while (kalendertag.getDay() !== wochentagKalendertagDay) {
      kalendertag = addDays(kalendertag, 1);
    }

    do {
      const sendetag = KanalOffsetUtils.getSendetag(
        kalendertag,
        beginnzeit,
        ansichtViewModel.kanal,
      );
      const sendetagKey = DateFnsService.formatDateAsString(sendetag);

      // Start/Ende der Blockansicht Zeile betrachtet einen "echten" Abschnitt auf dem Zeitstrahl,
      // daher müssen wir zu Berechnung den Kalendertag verwenden
      const start = setMinutes(setHours(kalendertag, beginnzeitStunden), beginnzeitMinuten);
      const end = addMinutes(start, laengeInMinuten);

      rows[sendetagKey] = {
        sendetag: sendetagKey,
        kalendertag: DateFnsService.formatDateAsString(kalendertag),
        start,
        end,
        varianten: [
          {
            variante: 0,
            pillen: [],
          },
        ],
      };

      kalendertag = addDays(kalendertag, 7);
    } while (kalendertag < ansichtViewModel.zeitraum.end);

    return rows;
  }

  static filterPlanungsobjekteForBlockansichtdefinitionen(
    blockansichtDefinitionen: BlockansichtDefinition[],
    planungsobjekte: PlanungsobjektLinearDto[],
  ): PlanungsobjektLinearDto[] {
    const blockansichtIntervals =
      BlockansichtMapper.getIntervalsForBlockansichten(blockansichtDefinitionen);
    const filtered = planungsobjekte.filter((planungsobjekt) => {
      const planungsobjektInterval = {
        start: new Date(planungsobjekt.von),
        end: new Date(planungsobjekt.bis),
      };
      try {
        return blockansichtIntervals.some((blockansichtInterval) => {
          return areIntervalsOverlapping(blockansichtInterval, planungsobjektInterval);
        });
      } catch (error) {
        console.error(error);
        return [];
      }
    });
    return filtered;
  }

  static getIntervalsForBlockansichten(blockansichten: BlockansichtDefinition[]) {
    return blockansichten.flatMap((blockansicht) => {
      return BlockansichtMapper.getIntervalsForBlockansicht(blockansicht);
    });
  }

  static getIntervalsForBlockansicht(blockansicht: BlockansichtDefinition) {
    const schemaplatz = blockansicht.ansichtViewModel.ansichtsdefinition.schemaplaetze[0];
    const [beginnzeitStunden, beginnzeitMinuten] = schemaplatz.beginnzeit.split(":").map(Number);
    const laengeInSekunden = schemaplatz.laenge;

    return eachDayOfInterval(blockansicht.ansichtViewModel.zeitraum)
      .filter((dateInYear) => {
        // Wir möchten für die Zeilen der Blockansicht "echte" Zeitbereiche berechnen,
        // daher verwenden wir den Wochentag Kalendertag
        return dateInYear.getDay() === wochentagToDayOfWeek[blockansicht.wochentagKalendertag];
      })
      .map((dateOfRow) => {
        const start = setMinutes(setHours(dateOfRow, beginnzeitStunden), beginnzeitMinuten);
        const end = addSeconds(start, laengeInSekunden);
        return { start, end };
      });
  }
}
