import { isSameDay, isSameISOWeek, isSameMonth } from "date-fns";
import { box, unbox } from "ngrx-forms";
import {
  PlanungsobjektOnDemandVm,
  PlanungsobjektOnDemandWithLinearVm,
} from "src/app/core/stores/on-demand/on-demand.model";
import { PlanungsobjektLinearVm } from "src/app/core/stores/planungsobjekt/planungsobjekt-linear.model";
import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { Genre } from "src/app/models/openapi/model/genre";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { DateFnsService } from "src/app/services/date-fns.service";
import { asArray, asSingleValueOrNull } from "src/app/utils/array-utils";
import { toWochentagShort } from "src/app/utils/date-utils";
import { toEnumArray } from "src/app/utils/enum-utils";
import { OnDemandSearchFormData } from "../on-demand-page.model";
import {
  OnDemandGroupingCriteria,
  OnDemandOrderingProperty,
  OnDemandTableColumnDefinition,
  PlanungsobjektOnDemandItemRowVm,
  TimespanDescription,
} from "./on-demand-table.model";

export function mapToPlanungsobjektOnDemandItemRowVm(
  planungsobjektOnDemandWithLinear: PlanungsobjektOnDemandWithLinearVm,
  timespanPerColumn: TimespanDescription,
  columnDefinitions: OnDemandTableColumnDefinition[],
): PlanungsobjektOnDemandItemRowVm {
  const { onlineAb, onlineBis } = planungsobjektOnDemandWithLinear;
  const kalendertag = planungsobjektOnDemandWithLinear.linear?.kalendertag;

  const findColumnIndex = (date: Date) => {
    switch (timespanPerColumn) {
      case "day":
        return columnDefinitions.findIndex((column) => isSameDay(column.date, date));
      case "week":
        return columnDefinitions.findIndex((column) => isSameISOWeek(column.date, date));
      case "month":
        return columnDefinitions.findIndex((column) => isSameMonth(column.date, date));
    }
  };

  const firstColumn = columnDefinitions[0];
  const lastColumn = columnDefinitions[columnDefinitions.length - 1];
  const isOutsideOfTimerange = onlineBis < firstColumn.date || onlineAb > lastColumn.date;

  const onlineAbPosition = findColumnIndex(onlineAb);
  const isOverflowingLeft = onlineAbPosition === -1;
  const onlineBisPosition = findColumnIndex(onlineBis);
  const isOverflowingRight = onlineBisPosition === -1;

  const width =
    isOverflowingLeft && isOverflowingRight
      ? columnDefinitions.length
      : isOverflowingLeft
        ? onlineBisPosition + 1
        : isOverflowingRight
          ? columnDefinitions.length - onlineAbPosition
          : onlineBisPosition - onlineAbPosition + 1;

  const offset = isOverflowingLeft ? 0 : onlineAbPosition;

  const kalendertagDate = new Date(kalendertag);
  const linearIsOutsideOfTimerange =
    (kalendertag && kalendertagDate < firstColumn.date) || kalendertagDate > lastColumn.date;
  const linearOffset = kalendertag ? findColumnIndex(kalendertagDate) : -1;
  const linearPosition =
    kalendertag && linearOffset !== -1
      ? {
          isOutsideOfTimerange: linearIsOutsideOfTimerange,
          offset: linearOffset,
        }
      : undefined;

  return {
    ...planungsobjektOnDemandWithLinear,
    position: {
      isOutsideOfTimerange,
      offset,
      width,
      isOverflowingLeft,
      isOverflowingRight,
    },
    linear: planungsobjektOnDemandWithLinear.linear
      ? {
          ...planungsobjektOnDemandWithLinear.linear,
          position: linearPosition,
          tooltip: createLinearTooltip(planungsobjektOnDemandWithLinear.linear),
        }
      : undefined,
    tooltip: createTooltip(planungsobjektOnDemandWithLinear),
  };
}

export function convertPlanungsobjektOnDemandVmToPlanungsobjektDto(
  planungsobjekt: PlanungsobjektOnDemandVm,
): PlanungsobjektOnDemandDto {
  return {
    ...planungsobjekt,
    createdAt: planungsobjekt.createdAt?.toISOString().split("T")[0],
    modifiedAt: planungsobjekt.modifiedAt?.toISOString().split("T")[0],
    onlineAb: planungsobjekt.onlineAb.toISOString().split("T")[0],
    onlineBis: planungsobjekt.onlineBis.toISOString().split("T")[0],
  };
}

export function createTooltip(planungsobjekt: PlanungsobjektOnDemandWithLinearVm) {
  const onDemandTooltip = createOnDemandTooltip(planungsobjekt);
  if (!planungsobjekt.linear) {
    return onDemandTooltip;
  }

  const linearTooltip = createLinearTooltip(planungsobjekt.linear);

  return `${onDemandTooltip}\n${linearTooltip}`;
}

export function createOnDemandTooltip(planungsobjekt: PlanungsobjektOnDemandWithLinearVm) {
  const toLocaleDateString = new Intl.DateTimeFormat("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const onlineAbWochentagShort = toWochentagShort(planungsobjekt.onlineAb);
  const onlineAb = toLocaleDateString.format(planungsobjekt.onlineAb);
  const onlineBisWochentagShort = toWochentagShort(planungsobjekt.onlineBis);
  const onlineBis = toLocaleDateString.format(planungsobjekt.onlineBis);

  return `Online ab: ${onlineAbWochentagShort}, ${onlineAb} Online bis: ${onlineBisWochentagShort}, ${onlineBis}`;
}

export function createLinearTooltip({ kanal, sendetag, beginnzeit }: PlanungsobjektLinearVm) {
  const sendetagWochentagShort = toWochentagShort(sendetag);
  const sendetagString = DateFnsService.parseStringToGermanDateString(sendetag);
  return `Ausstrahlung: ${kanal} ${sendetagWochentagShort}, ${sendetagString}, ${beginnzeit} Uhr`;
}

const uniqueValues = (array: string[]): string[] => Array.from(new Set(array));

export const urlParamsToSearchFormValue = (params: {
  [key: string]: string | string[];
}): Partial<OnDemandSearchFormData> => {
  const von = asSingleValueOrNull(params.von) ?? undefined;
  const bis = asSingleValueOrNull(params.bis) ?? undefined;

  const orderBy = params.order
    ? (asSingleValueOrNull(params.order) as OnDemandOrderingProperty)
    : undefined;

  const redaktionen = params.redaktionen
    ? box(toEnumArray(uniqueValues(asArray(params.redaktionen)), Object.values(Redaktion)))
    : undefined;
  const genres = params.genres
    ? box(toEnumArray(uniqueValues(asArray(params.genres)), Object.values(Genre)))
    : undefined;
  const planungskontexte = params.planungskontexte
    ? box(
        toEnumArray(uniqueValues(asArray(params.planungskontexte)), Object.values(Planungskontext)),
      )
    : undefined;
  const contentCommunities = params.contentCommunities
    ? box(
        toEnumArray(
          uniqueValues(asArray(params.contentCommunities)),
          Object.values(ContentCommunity),
        ),
      )
    : undefined;

  const groupByProperties = uniqueValues(asArray(params.group));

  const formData = {
    orderByProperty: orderBy,
    groupByPropertyOne: groupByProperties[0] as OnDemandGroupingCriteria,
    groupByPropertyTwo: (groupByProperties[1] ?? undefined) as OnDemandGroupingCriteria | undefined,
    useOperatorUND: asSingleValueOrNull(params.logicOperator) === "UND",
    filterRedaktionen: redaktionen,
    filterGenres: genres,
    filterPlanungskontexte: planungskontexte,
    filterContentCommunities: contentCommunities,
    timerangeOnlineAb: von,
    timerangeOnlineBis: bis,
  };

  return Object.entries(formData)
    .filter(([_key, value]) => value !== undefined)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const searchFormValueToUrlParams = (value: OnDemandSearchFormData) => {
  const genres = unbox(value.filterGenres);
  const redaktionen = unbox(value.filterRedaktionen);
  const planungskontexte = unbox(value.filterPlanungskontexte);
  const contentCommunities = unbox(value.filterContentCommunities);

  return {
    von: value.timerangeOnlineAb,
    bis: value.timerangeOnlineBis,
    genres,
    redaktionen,
    planungskontexte,
    contentCommunities,
    group: [value.groupByPropertyOne ?? "", value.groupByPropertyTwo ?? ""],
    order: value.orderByProperty,
    logicOperator: value.useOperatorUND ? "UND" : "ODER",
  };
};
