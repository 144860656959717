import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { onDemandActions } from "src/app/core/stores/on-demand/on-demand.actions";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { onDemandPageActions } from "./on-demand-page.actions";
import { onDemandSearchFormReducer } from "./on-demand-page.form";
import { OnDemandPageState, initialState } from "./on-demand-page.model";

const onDemandFeatureReducer = createReducer(
  initialState,
  on(
    onDemandPageActions.toggleSidebar,
    (state, { isOpen }): OnDemandPageState => ({
      ...state,
      sidebarExpanded: isOpen !== undefined ? isOpen : !state.sidebarExpanded,
    }),
  ),
  on(
    onDemandPageActions.activateTab,
    (state, { tab }): OnDemandPageState => ({
      ...state,
      activeSidebarTab: tab,
    }),
  ),
  on(
    onDemandPageActions.setSearchNeeded,
    (state): OnDemandPageState => ({
      ...state,
      searchNeeded: true,
    }),
  ),
  on(
    onDemandActions.loadOnDemandSuccess,
    (state): OnDemandPageState => ({
      ...state,
      searchNeeded: false,
    }),
  ),
  on(onDemandPageActions.resetOnDemandState, (): OnDemandPageState => initialState),
  onNgrxForms(),
);

const reducer = mergeReducers(onDemandFeatureReducer, onDemandSearchFormReducer);

export const onDemandPageFeature = createFeature({
  name: "OnDemandPage",
  reducer,
});
