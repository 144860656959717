import { createSelector } from "@ngrx/store";
import { DateFnsService } from "src/app/services/date-fns.service";
import { serienWindowFormSelectors } from "./serien-window.form";
import { SerienWindowResult } from "./serien-window.model";
import { serienWindowFeauture } from "./serien-window.reducer";

const selectFormState = serienWindowFeauture.selectFormState;
const formSelectors = serienWindowFormSelectors(selectFormState);

const selectWindowInput = serienWindowFeauture.selectWindowInput;

const selectFocusedDate = createSelector(selectWindowInput, (input) => {
  const sendetag = input?.planungsobjekt?.publikationsplanung?.sendetag;
  return sendetag ? DateFnsService.parseStringToDate(sendetag) : new Date();
});

const selectSerienWindowResult = createSelector(
  selectWindowInput,
  formSelectors.selectFormData,
  (input, formValue): SerienWindowResult | undefined => {
    if (!input || !formValue) {
      return undefined;
    }

    return {
      context: input.context,
      params: {
        folgennummerAb: formValue.folgennummerAb ? formValue.folgennummerAb : 1,
        folgennummerBis: formValue.folgennummerBis ? formValue.folgennummerBis : 1,
        staffelnummer: formValue.staffelnummer ? formValue.staffelnummer : null,
        anzahlFolgen: formValue.anzahlFolgen ? formValue.anzahlFolgen : null,
        reihenfolgeVerlinken: formValue.reihenfolgeVerlinken
          ? formValue.reihenfolgeVerlinken
          : false,
        planungsobjekt: input.planungsobjekt,
        auszulassendeDaten: (formValue.auszulassendeDaten.value ?? []).map((date) => date),
      },
    };
  },
);

export const serienWindowSelectors = {
  // Window Selectors
  selectWindowInput,
  selectFocusedDate,
  selectSerienWindowResult,

  // Form Selectors
  ...formSelectors,
};
