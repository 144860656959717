import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, catchError, finalize } from "rxjs";
import { shellActions } from "src/app/core/stores/shell/shell.actions";
import { ClientLogLevel } from "src/app/models/openapi/model/client-log-level";
import { LoadingDialogService } from "src/app/services/loading-dialog.service";
import { HttpRequestError, RequestData } from "../../errors/http-request-error";

/**
 * Das publish.it Backend gibt in jedem Fehlerfall ein Problem Details
 * Objekt zurück. Unabhängig davon, ob es sich um einen erwarteten
 * Domain/Business-Fehler oder unerwarteten technische Fehler handelt.
 * https://datatracker.ietf.org/doc/html/rfc7807
 */
export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  extensions?: [string, string];
  errors?: [string, string[]];
}

export const SKIP_LOADING_INTERCEPTOR = new HttpContextToken(() => false);

/**
 * Interceptor zum Anzeigen des Ladespinners und ggf. zum Abfangen von auftretenden Errors.
 */
@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  constructor(
    private loadingDialogService: LoadingDialogService,
    private store: Store,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(SKIP_LOADING_INTERCEPTOR)) {
      return next.handle(request);
    }

    this.loadingDialogService.addPendingRequest();
    this.store.dispatch(shellActions.requestStarted());

    return next.handle(request).pipe(
      finalize(() => {
        this.loadingDialogService.removePendingRequest();
        this.store.dispatch(shellActions.requestCompleted());
      }),
      catchError((httpResponseError: unknown) => {
        const requestData: RequestData = {
          payload: JSON.stringify(request.body),
          url: request.url,
          urlWithParams: request.urlWithParams,
          method: request.method,
        };

        // TODO: Wann tritt dieser Fall auf?
        // Macht es dann überhaupt Sinn, den Fehler als HttpRequestError zu werfen?
        if (!(httpResponseError instanceof HttpErrorResponse)) {
          throw new HttpRequestError(
            "Error is not of expected type HttpErrorResponse.",
            ClientLogLevel.ERROR,
            undefined,
            requestData,
          );
        }

        let message = httpResponseError.message;

        // Im Fehlerfall wird ein Problem Details Objekt aus dem Backend zurückgegeben
        // Dieses beinhaltet unter anderem eine an den Anwender gerichtete Fehlermeldung, ggf. Validierungsfehler
        // sowie eine technische Correlation ID für die weitere Fehleranalyse
        // Return Codes wie z.B. 403 Forbidden haben keinen Body und damit auch kein Problem Details Objekt
        if (httpResponseError.error) {
          try {
            if (typeof httpResponseError.error === "string") {
              throw new Error();
            }
            const problemDetails = httpResponseError.error as ProblemDetails;
            message = this.getErrorMessage(problemDetails);
            // correlationId = problemDetails.
          } catch {
            // publish.it Backend sollte in _jedem_ Fehlerfall ein ProblemDetails Objekt zurückgeben
            console.error(
              "Received backend error that does not conform to Problem Details RFC7807",
              httpResponseError,
            );
          }
        }

        throw new HttpRequestError(
          message,
          ClientLogLevel.ERROR,
          httpResponseError.status,
          requestData,
        );
      }),
    );
  }

  /**
   * Im Falle eines Validierungsfehlers, ist das Property errors mit allen fehlerhaften Feldern
   * und lokalisierten Nachrichten befüllt. Sonst wird der Titel zurückgegeben.
   */
  private getErrorMessage(problemDetails: ProblemDetails): string {
    let message = problemDetails.title;

    if (problemDetails.errors) {
      let validationErrors = [];
      for (const [_fieldName, fieldErrors] of Object.entries(problemDetails.errors)) {
        validationErrors = validationErrors.concat(...fieldErrors);
      }
      if (validationErrors.length > 0) {
        message = validationErrors.join(" ");
      }
    }

    if (!message) {
      message = "Ein unspezifizierter Fehler ist aufgetreten.";
    }

    return message;
  }
}
