import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { box } from "ngrx-forms";
import { serienWindowActions } from "src/app/core/stores/serien-window/serien-window.actions";
import { serienWindowFormActions } from "src/app/core/stores/serien-window/serien-window.form";
import { serienWindowSelectors } from "src/app/core/stores/serien-window/serien-window.selectors";

@Injectable()
export class SerienWindowFacade {
  private readonly store = inject(Store);

  public readonly formState$ = this.store.select(serienWindowSelectors.selectFormState);
  public readonly windowInput$ = this.store.select(serienWindowSelectors.selectWindowInput);

  public readonly focusedDate$ = this.store.select(serienWindowSelectors.selectFocusedDate);

  public save() {
    this.store.dispatch(serienWindowFormActions.trySaving());
  }

  public closeWindow() {
    this.store.dispatch(serienWindowActions.tryClosingWithoutSaving());
  }

  public removeAuszulassendeDaten() {
    this.store.dispatch(
      serienWindowFormActions.patchValue({ value: { auszulassendeDaten: box([]) } }),
    );
  }
}
