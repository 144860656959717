import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, tap } from "rxjs";
import { notificationActions } from "src/app/core/stores/notification/notification.actions";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { WithNotificationsDto } from "src/app/models/openapi/model/with-notifications-dto";
import { isEnumValue } from "src/app/utils/enum-utils";

const isWithNotificationsDto = (response: unknown): response is WithNotificationsDto => {
  return (
    typeof response === "object" &&
    response !== null &&
    "notifications" in response &&
    Array.isArray(response.notifications) &&
    response.notifications.every(
      (notification: unknown) =>
        typeof notification === "object" &&
        notification !== null &&
        "type" in notification &&
        typeof notification.type === "string" &&
        isEnumValue(notification.type, Object.values(NotificationStyle)) &&
        "nachricht" in notification &&
        typeof notification.nachricht === "string",
    )
  );
};

/**
 * Prüft die Response auf Side-Effect-Notifications und zeigt diese an.
 */
@Injectable()
export class SideEffectNotificationInterceptor implements HttpInterceptor {
  private readonly store = inject(Store);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (
          response instanceof HttpResponse &&
          isWithNotificationsDto(response.body) &&
          response.body.notifications.length
        ) {
          this.store.dispatch(
            notificationActions.showNotifications({
              sideEffectNotificationDto: response.body.notifications,
            }),
          );
        }
      }),
    );
  }
}
