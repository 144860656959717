import { createSelector } from "@ngrx/store";
import { Role } from "src/app/models/openapi/model/role";
import { YearDropdownItem } from "src/app/on-demand/state/on-demand-page.model";
import { permissionFeature } from "./permissions.reducer";

const selectPermissionsAll = permissionFeature.selectPermissions;

const selectPermissionForRole = (role: Role) => {
  return createSelector(permissionFeature.selectPermissionState, (state) =>
    state.permissions?.find((permission) => permission.role === role),
  );
};

const selectGeltungsbereichYearsForOnDemand = createSelector(
  selectPermissionForRole(Role.PLANUNGSOBJEKT_ZDF_LESEN),
  (permissions): YearDropdownItem[] => {
    if (!permissions || !permissions.geltungsbereiche) return [];

    const years: YearDropdownItem[] = permissions.geltungsbereiche.map((year) => {
      return {
        text: year,
        value: {
          von: `${year}-01-01`,
          bis: `${year}-12-31`,
        },
      };
    });
    return years;
  },
);

export default {
  selectPermissionsAll,
  selectPermissionForRole,
  selectGeltungsbereichYearsForOnDemand,
};
