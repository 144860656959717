import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { OnDemandPageTab } from "./on-demand-page.model";

export const onDemandPageActions = createActionGroup({
  source: "OnDemandPage",
  events: {
    init: emptyProps(),
    toggleSidebar: props<{ isOpen?: boolean }>(),
    activateTab: props<{ tab: OnDemandPageTab }>(),
    setSearchNeeded: emptyProps(),
    resetOnDemandState: emptyProps(),
  },
});
