import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Action, ActionCreator, ActionReducer, Store } from "@ngrx/store";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { FormGroupState } from "ngrx-forms";
import { Observable, OperatorFunction, filter, map, of, switchMap, takeUntil } from "rxjs";
import { notificationActions } from "../core/stores/notification/notification.actions";
import routerSelectors from "../core/stores/router/router.selectors";
import { CustomDialogService } from "../services/custom-dialog.service";

/**
 * Nimmt eine Liste von Reducern und gibt einen neuen Reducer zurück,
 * der die Auswirkungen aller Reducer kombiniert.
 * @param reducers
 * @returns
 */
export function mergeReducers<TState>(
  ...reducers: ActionReducer<TState, Action>[]
): ActionReducer<TState, Action> {
  if (reducers.length === 0) {
    throw new Error("At least one reducer was expected");
  }

  return ((state: TState, action: Action): TState =>
    reducers.reduce(
      (initialState, reducer) => reducer(initialState, action),
      state,
    )) as ActionReducer<TState, Action>;
}

/**
 * Eine Observable Source, die nur dann Werte emittiert,
 * wenn die URL mit dem angegebenen Segment übereinstimmt.
 *
 * Kann in einem Effect verwendet werden, um auf einer Seite bestimmte Dinge zu tun.
 *
 * @param segment
 * @param store
 * @returns
 */
export const onPage = (segment: string, store = inject(Store)): Observable<string> =>
  store
    .select(routerSelectors.selectUrl)
    .pipe(filter((route) => !!route && route.startsWith(`/${segment}`)));

/**
 * Eine RxJs-Operator-Funktion, die nur Werte emittiert,
 * wenn die URL mit dem angegebenen Segment übereinstimmt.
 *
 * Wie filter, aber für die URL.
 *
 * @param segment
 * @param store
 * @returns
 */
export const ifOnPage = <T>(segment: string, store = inject(Store)): OperatorFunction<T, T> => {
  return (source$: Observable<T>): Observable<T> => {
    // Observable that emits when URL no longer matches the segment
    const notOnPage$ = store.select(routerSelectors.selectUrl).pipe(
      filter((url: string) => !!url && !url.startsWith(`/${segment}`)), // Emits when the URL does NOT match the segment
    );

    return store.select(routerSelectors.selectUrl).pipe(
      filter((url: string) => !!url && url.startsWith(`/${segment}`)), // Only continue if URL matches the segment
      switchMap(() => source$.pipe(takeUntil(notOnPage$))), // Stop emitting when the URL no longer matches
    );
  };
};

/**
 * Prüft, ob der angegebene Formularzustand gültig ist.
 * @param formState$
 * @returns
 */
export const ifFormStateIsValid =
  <T>(formState$: Observable<FormGroupState<any>>) =>
  (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      concatLatestFrom(() => formState$),
      filter(([_, formState]) => formState.isValid),
      map(([value]) => value),
    );

/**
 * Optionen Typ für den Automatischen Bestätigungs Effekt.
 *
 * @param tryClosingAction Aktion die ausgelöst wird, wenn versucht wird das Fenster zu schließen.
 * @param closingAction Aktion die ausgelöst wird, wenn das Fenster wirklich geschlossen wird.
 * @param preventClosingWhen$ Observable, das angibt, ob das Fenster geschlossen werden kann.
 */
export type ConfirmDialogOptions = {
  tryClosingAction: ActionCreator;
  closingAction: ActionCreator<string, () => Action<string>>;
  preventClosingWhen$: Observable<boolean>;
};

/**
 * Erzeugt einen Effekt, der ein Bestätigungsdialog öffnet, wenn das Fenster geschlossen werden soll.
 * @param options
 * @returns
 */
export const createConfirmClosingEffect = (options: ConfirmDialogOptions) =>
  createEffect((action$ = inject(Actions), customDialogService = inject(CustomDialogService)) => {
    return action$.pipe(
      ofType(options.tryClosingAction),
      concatLatestFrom(() => options.preventClosingWhen$),
      switchMap(([_, preventClosing]) => {
        return preventClosing
          ? customDialogService.openConfirmCloseWindowDialog().result.pipe(
              filter((result) => !(result instanceof DialogCloseResult)),
              map(() => options.closingAction()),
            )
          : of(options.closingAction());
      }),
    );
  });

export type TrySavingOptions = {
  trySavingAction: ActionCreator;
  saveAction: ActionCreator<string, () => Action<string>>;
  formIsValid$: Observable<boolean>;
};
/**
 * Erzeugt einen Effekt, der versucht, das Formular zu speichern, wenn der Benutzer auf "Speichern" klickt.
 * Dazu wird überprüft, ob das Formular gültig ist.
 * Wenn ja wird die Save Action dispatched, ansonsten eine Fehlermeldung.
 *
 * @param options
 * @returns
 */
export const createTrySavingEffect = (options: TrySavingOptions) =>
  createEffect((actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(options.trySavingAction),
      concatLatestFrom(() => [options.formIsValid$]),
      map(([_, isValid]) =>
        isValid
          ? options.saveAction()
          : notificationActions.showNotification({
              message: "Das Formular enthält noch Fehler",
              notificationType: "Error",
            }),
      ),
    );
  });
